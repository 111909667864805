import { useTransition } from "react"

import type { InferReturn } from "~/@types/generics"

export function INPDodger({
  children,
}: {
  children: ({ cn, onTransition }: { cn: string; onTransition: InferReturn<typeof useTransition>[1] }) => JSX.Element
}) {
  const [isTransitioning, onTransition] = useTransition()
  return children({ cn: isTransitioning ? "isTransitioning" : "", onTransition })
}
