import { useQuery } from "@tanstack/react-query"

import { fetchAlgoliaRecommendations } from "~/data/product/algolia-recommendations/get"
import { useGetCart } from "~/hooks/useGetCart"
import useLocale from "~/hooks/useLocale"
import { serializeCardCompleteTheLookCard } from "~/components/ui/CartPanel/CompleteTheLookSlider/Card/data/serializer"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { uniq } from "~/utils/uniq"

export function useCompleteTheLookSliderProductCards() {
  const t = useTranslate()
  const locale = useLocale()

  const { cartPayload } = useGetCart()

  const ids = uniq(cartPayload?.lines?.map((line) => line?.merchandise?.product?.id)?.filter(Boolean) ?? []) ?? []

  const title = t("cart_complete_the_look")

  return useQuery({
    enabled: Boolean(ids.length),
    queryKey: ["CompleteTheLookSliderProductCards", ids],
    queryFn: async () => {
      const recommendations = await fetchAlgoliaRecommendations({
        ids,
        locale,
      })

      return {
        title,
        items:
          recommendations
            ?.map((object) => serializeCardCompleteTheLookCard(object?.product, locale))
            ?.filter(Boolean) ?? [],
      }
    },
  })
}
