import type { UnlikelyVariant } from "@unlikelystudio/commerce-connector"
import { useStoreContext } from "@unlikelystudio/react-ecommerce-hooks"

import { productVariantMetafields } from "~/lib/shopify/constants"
import { getLocalizedMetafields } from "~/lib/shopify/utils/get-localized-metafields"
import useLocale from "~/hooks/useLocale"
import { serializeVpPrices } from "~/components/ui/ProductHeader/_data/serialize-vp-price"
import { metafieldsToObject } from "~/utils/metafields-to-object"

export function useProcessVariantVPPrice({ variant }: { variant: UnlikelyVariant }) {
  const locale = useLocale()
  const { country } = useStoreContext()

  const priceCurrencyCode = variant?.price?.currencyCode ?? null

  const variantMetafields =
    variant.metafields &&
    metafieldsToObject(variant.metafields, getLocalizedMetafields(productVariantMetafields, locale))

  if (variantMetafields?.priceMarketsSales && priceCurrencyCode) {
    return serializeVpPrices(variantMetafields?.priceMarketsSales, country, priceCurrencyCode)
  }

  return null
}
