import { forwardRef, type Ref } from "react"
import { clsx } from "clsx"

import type { UnlikelyProduct } from "@unlikelystudio/commerce-connector"
import { useGetCustomer, useGetProduct } from "@unlikelystudio/react-ecommerce-hooks"

import { productMetafields } from "~/lib/shopify/constants"
import { getDefaultVariant } from "~/lib/shopify/utils/get-default-variants"
import { getLocalizedMetafields } from "~/lib/shopify/utils/get-localized-metafields"
import { productHasStockAlert } from "~/lib/shopify/utils/product-utils"
import useLocale from "~/hooks/useLocale"
import { reorderImages } from "~/components/ui/AlgoliaCollectionGrid/AlgoliaListingGrid/_utils/get-first-image-type"
import { serializeSfBackInStock } from "~/components/ui/BackInStockPopin/_data/serializer"
import CardTags from "~/components/ui/CardTags"
import type { ImageProps } from "~/components/ui/Image"
import { Link } from "~/components/ui/Link"
import { hrefResolver } from "~/components/ui/Link/utils/href-resolver"
import Price from "~/components/ui/Price"
import { serializeSfPrice } from "~/components/ui/Price/_data/serializer"
import { refetchOnInterval } from "~/components/ui/ProductCardRevamped/_utils/refetch-on-interval"
import { BackInStockButton } from "~/components/ui/ProductCardRevamped/CardContent/BackInStock/index.client"
import { ColorSibling } from "~/components/ui/ProductCardRevamped/CardContent/ColorSibling/index.client"
import {
  ScrollSnapGallery,
  type CardMediaProps,
} from "~/components/ui/ProductCardRevamped/CardContent/ScrollSnapGallery/index.client"
import type { ProductCardRevampedProps } from "~/components/ui/ProductCardRevamped/index.client"
import { QuickbuyRevamped } from "~/components/ui/ProductCardRevamped/Quickbuy/index.client"
import { serializeVariants } from "~/components/ui/ProductHeader/_data/serialize-variant"
import { isUnlikelyMediaVideo, serializeMedia } from "~/components/ui/ProductHeader/_data/serializer"
import { ALGOLIA_TRACK_QUERY_PARAM } from "~/components/shared/AlgoliaAnalyticsEvents/index.client"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import isTouchScreen from "~/utils/is-touch-screen"
import { metafieldsToObject } from "~/utils/metafields-to-object"
import { processVpPrice } from "~/utils/vp-prices/process-vp-prices"

import * as css from "./styles.css"

const COLORS_TO_SLICE = 3

function ForwardedCardContent(
  {
    initialProduct,
    onClick,
    priority,
    siblings,
    firstImageType = "worn",
    refetchOnClientSide,
    trackAsAlgoliaConversion = false,
    hasDrag,
  }: Omit<ProductCardRevampedProps, "className" | "product"> & {
    initialProduct: UnlikelyProduct
    refetchOnClientSide?: boolean
    trackAsAlgoliaConversion: boolean
  } & Pick<ImageProps, "priority">,
  ref: Ref<HTMLDivElement>
) {
  const { data: product, refetch, dataUpdatedAt } = useGetProduct()

  const locale = useLocale()

  const t = useTranslate()

  const { data: customer } = useGetCustomer()

  if (!product?.id) {
    return null
  }

  const variants = serializeVariants(product?.variants, locale)
  const defaultVariant = getDefaultVariant(variants, product)

  if (!defaultVariant?.id) return null

  const { title, priceRange } = product ?? {}
  const { price, compareAtPrice } = defaultVariant ?? {}

  const minVariantPrice = serializeSfPrice(locale, priceRange?.minVariantPrice)?.price
  const maxVariantPrice = serializeSfPrice(locale, priceRange?.maxVariantPrice)?.price
  const hasRange =
    priceRange?.minVariantPrice?.amount !== priceRange?.maxVariantPrice?.amount && minVariantPrice && maxVariantPrice

  if (!price && !compareAtPrice) {
    return null
  }

  const href = hrefResolver({ id: product?.id, handle: product?.handle }, locale) ?? undefined

  const processedHref = trackAsAlgoliaConversion ? `${href}?${ALGOLIA_TRACK_QUERY_PARAM}=1` : href

  // Setting the price
  const { processedPrice, processedCompareAtPrice } = processVpPrice({
    price,
    compareAtPrice,
    vpPrice: defaultVariant?.vpPrice,
    customer,
  })

  const sfPrice = serializeSfPrice(locale, processedPrice, processedCompareAtPrice)

  // Getting the out of stock state
  const isOutOfStock = variants?.every((variant) => !variant.availableForSale) ?? []

  // Getting the gift card state
  const { isGiftCard } = product ?? {}

  // Getting the stock alert state
  const hasStockAlert = productHasStockAlert(product)

  // display quick buy if:
  // - product is not out of stock
  // - product is out of stock and stock alert is disabled
  const shouldDisplayQuickBuy = !isTouchScreen && (!isOutOfStock || (isOutOfStock && !hasStockAlert))

  // Getting metafields
  const serializedMetafields = product?.metafields
    ? metafieldsToObject(product.metafields, getLocalizedMetafields(productMetafields, locale))
    : null

  // Getting card tags
  const cardTags = serializedMetafields?.cardTags?.split(",") ?? []

  // BIS serialization
  const backInStock = hasStockAlert ? serializeSfBackInStock(product, locale) ?? null : null

  // Getting video state
  const hasVideoOnCard = serializedMetafields?.videoPriorityOnCollection === "true"

  // Ordered medias
  const medias = (product?.media
    ?.map((media) => {
      return {
        type: isUnlikelyMediaVideo(media) ? "video" : "image",
        data: serializeMedia(media, locale),
      }
    })
    ?.filter((media) => media?.data) ?? []) as CardMediaProps[]

  // Getting the product images, if it has a video, no need to reorder the images by the imageType
  const orderedMedias = hasVideoOnCard
    ? medias
    : reorderImages(
        medias?.filter((media) => media?.type === "image"),
        firstImageType
      )

  return (
    <div
      className={css.cardContent}
      ref={ref}
      onClick={onClick}
      onMouseEnter={() => {
        if (refetchOnClientSide) {
          refetchOnInterval(dataUpdatedAt, refetch)
        }
      }}
    >
      {cardTags?.length > 0 && <CardTags className={css.tags} items={cardTags?.slice(0, 2) ?? []} />}
      <div className={css.cover}>
        <ScrollSnapGallery
          isOutOfStock={isOutOfStock && !isGiftCard}
          href={processedHref}
          medias={orderedMedias}
          hasDrag={hasDrag}
          priority={priority}
        />
        {shouldDisplayQuickBuy && (
          <QuickbuyRevamped
            className={css.quickbuy}
            trackAsAlgoliaConversion={trackAsAlgoliaConversion}
            shouldFetchInventories={Boolean(product?.id && initialProduct?.id && initialProduct?.id !== product?.id)}
          />
        )}
        {!isGiftCard && isOutOfStock && hasStockAlert && (
          <BackInStockButton backInStock={backInStock} className={css.outOfStockLayer} />
        )}
      </div>
      <div className={css.baseline}>
        <Link aria-label={title} href={processedHref}>
          {title && <p className={css.title}>{title}</p>}
          {!product?.isGiftCard && sfPrice && <Price className={css.price} {...sfPrice} />}

          {/* display all gift card values as "price" https://app.clickup.com/t/2688547/DAREL-2907 */}
          {product?.isGiftCard && variants?.length > 0 && minVariantPrice && (
            <p className={clsx(css.price, css.giftCardValues)}>
              {hasRange ? t("gift_card_range", { from: minVariantPrice, to: maxVariantPrice }) : minVariantPrice}
            </p>
          )}

          {!isGiftCard && isOutOfStock && <span className={css.outOfStock}>{t("out_of_stock")}</span>}
        </Link>

        {siblings?.length > 0 && (
          <div className={css.colorSiblings}>
            {siblings.slice(0, COLORS_TO_SLICE).map((sibling, index) => {
              return <ColorSibling key={`color-sibling-${index}`} {...sibling} />
            })}
            {siblings.length > COLORS_TO_SLICE && (
              <Link aria-label={title} href={processedHref} className={css.moreColors}>
                +
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export const CardContent = forwardRef(ForwardedCardContent)
