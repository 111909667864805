import { productMetafields } from "~/lib/shopify/constants"
import { getLocalizedMetafields } from "~/lib/shopify/utils/get-localized-metafields"
import { getProductColorByValue } from "~/lib/shopify/utils/get-product-color-by-value"
import useLocale from "~/hooks/useLocale"
import { ColorSelector } from "~/components/ui/ColorSelector"
import { Link } from "~/components/ui/Link"
import { hrefResolver } from "~/components/ui/Link/utils/href-resolver"
import { useProductCardSiblings } from "~/components/ui/ProductCardRevamped/_providers/ProductCardColorProvider"
import type { ProductCardRevampedProps } from "~/components/ui/ProductCardRevamped/index.client"
import { metafieldsToObject } from "~/utils/metafields-to-object"

import { sprinkles } from "~/styles/sprinkles.css"

export function ColorSibling({ id, handle, metafields }: ProductCardRevampedProps["siblings"][number]) {
  const locale = useLocale()

  const { currentProductId, setCurrentProductId } = useProductCardSiblings()

  if (!id) return null

  const primaryColor = metafieldsToObject(
    metafields ?? {},
    getLocalizedMetafields(productMetafields, locale)
  )?.primaryColor

  if (!primaryColor) return null

  const href = hrefResolver({ id, handle }, locale)

  const color = {
    hexa: getProductColorByValue(primaryColor),
    name: primaryColor,
  }

  return (
    <div className={sprinkles({ position: "relative" })}>
      <Link
        // TODO: add title in siblings array for aria-label
        aria-label={color?.name}
        className={sprinkles({ pointerEvents: "none", position: "absolute" })}
        href={href || undefined}
      />
      <ColorSelector
        active={id === currentProductId}
        hexa={color?.hexa}
        layout="light"
        onClick={() => setCurrentProductId?.(id)}
        value={color?.name}
      />
    </div>
  )
}
