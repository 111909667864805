import type { Facet } from "~/lib/algolia/types"
import { objectValues } from "~/utils/object-values"

export const OBJECT_ID_PROPERTY = "objectID"
export const ALGOLIA_QUERY_ID_LOCAL_STORAGE_KEY = "algoliaQueryID"

export const SORT_BY_KEYS = {
  PRICE: "price",
} as const

export const SORT_BY = {
  PRICE_ASC: {
    SUFFIX: "price_asc",
    FIELD: `asc(${SORT_BY_KEYS.PRICE})`,
  },
  PRICE_DESC: {
    SUFFIX: "price_desc",
    FIELD: `desc(${SORT_BY_KEYS.PRICE})`,
  },
} as const

export const SORT_BY_SUFFIXES =
  objectValues(SORT_BY)
    ?.map((entry) => entry?.SUFFIX)
    ?.filter(Boolean) ?? []

export const SORT_BY_WITH_DEFAULT = ["default", ...SORT_BY_SUFFIXES] as const

export const SORT_BY_PARAM = "sort-by"

export const COLLECTIONS_FIELD = "collections"
export const TYPE_FIELD = "type"
export const MTC_FIELD = "mtc"
export const CUSTOM_CREATION_DATE_FIELD = "timestamp"
export const AVAILABILITY_FIELD = "isAvailable"

export const HIT_TYPES = {
  PRODUCT: "product",
  PROMO_CARD: "promo_card",
} as const

export const DEFAULT_RANKING = ["exact", "typo", "attribute", "words", "filters", "geo", "proximity", "custom"]

export const FACETS = {
  ACCESSORIES_SIZE: "facet_size_shoes",
  APPAREL: "facet_apparel",
  COLOR: "facet_color",
  DISCOUNT: "facet_discount",
  STANDARD_SIZE: "facet_size",
} as const

// decide the order in which the facets should be displayed in the filters panel
export const ORDERED_FACETS: Facet[] = [
  FACETS.DISCOUNT,
  FACETS.APPAREL,
  FACETS.STANDARD_SIZE,
  FACETS.ACCESSORIES_SIZE,
  FACETS.COLOR,
]

export { COLLECTION_HITS_PER_PAGE as SEARCH_HITS_PER_PAGE } from "~/lib/shopify/constants"

export const RULE_CONTEXT = "manual_merch"
