import { removeGidPrefix } from "@unlikelystudio/commerce-connector"
import { useUpdateCartAttributes } from "@unlikelystudio/react-ecommerce-hooks"

import { CHECKOUT_CUSTOM_ATTRIBUTES } from "~/lib/shopify/constants"
import { useGetCart } from "~/hooks/useGetCart"
import { safeJSONParse } from "~/utils/safe-json-parse"
import { uniq } from "~/utils/uniq"

export function useAlgoliaCartTrackingAttributes() {
  const { cartPayload } = useGetCart()
  const { mutate: updateCartCustomAttribute, isLoading: isLoadingCustomAttributes } = useUpdateCartAttributes()

  function updateAndCheckAlgoliaAttributes({
    variantId,
    trackAsAlgoliaConversion,
    direction,
  }: {
    variantId: string
    trackAsAlgoliaConversion: boolean
    direction: "add" | "remove"
  }) {
    // If we don't have the variant id, or custom attribtes are loading, or we don't have any cart payload
    // We don't do anything
    if (!variantId || isLoadingCustomAttributes || !cartPayload) {
      return
    }

    const trimmedVariantId = removeGidPrefix(variantId)

    if (!trimmedVariantId) {
      return
    }

    // Getting the current cart attributes
    const existingAttributes = cartPayload?.attributes ?? []

    // Getting the current algolia search event attributes
    const algoliaSearchEventAttributes = existingAttributes?.find(
      (item) => item?.key === CHECKOUT_CUSTOM_ATTRIBUTES.CHECKOUT_ALGOLIA_SEARCH_IDS
    )
    // Parsing the current algolia search event attributes
    let parsedSearchEventAttributes = safeJSONParse<number[]>(algoliaSearchEventAttributes?.value) ?? []

    // Checking if the variant is already in the attributes
    const variantIndexInAttributes = parsedSearchEventAttributes?.findIndex((id) => id === trimmedVariantId) ?? -1

    // If we're tracking as an algolia conversion, we only add the variant if it's not already in the attributes
    if (trackAsAlgoliaConversion) {
      if (direction === "add") {
        parsedSearchEventAttributes.push(trimmedVariantId)
      } else {
        parsedSearchEventAttributes = parsedSearchEventAttributes.filter((id) => id !== trimmedVariantId)
      }
    } else {
      if (variantIndexInAttributes > -1) {
        parsedSearchEventAttributes.splice(variantIndexInAttributes, 1)
      }
    }

    parsedSearchEventAttributes = uniq(parsedSearchEventAttributes)

    // We update the cart custom attributes with the new search event attributes
    updateCartCustomAttribute({
      cartId: cartPayload?.id,
      attributes: [
        ...cartPayload.attributes,
        {
          key: CHECKOUT_CUSTOM_ATTRIBUTES.CHECKOUT_ALGOLIA_SEARCH_IDS,
          value: JSON.stringify(parsedSearchEventAttributes),
        },
      ],
    })
  }

  return {
    updateAndCheckAlgoliaAttributes,
  }
}
