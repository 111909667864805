"use client"

import { useEffect, useRef, useState } from "react"
import { usePathname } from "next/navigation"
import type { RecipeVariants } from "@vanilla-extract/recipes"
import clsx from "clsx"

import type { Nullish } from "~/@types/generics"
import type { SbRichtext } from "~/lib/storyblok/schemas/default/richtext"
import { isRTFilled } from "~/lib/storyblok/utils/check-empty-string"
import useAuthentication from "~/hooks/account/useAuthentication"
import HtmlRichText from "~/components/ui/HtmlRichText"
import { Image, type ImageProps } from "~/components/ui/Image"
import InlineCta from "~/components/ui/InlineCta"
import type { TLinkLabel } from "~/components/ui/Link/_data/serializer"
import LoginPanel from "~/components/ui/Panels/Login"
import RichText from "~/components/ui/RichText"
import SquareCta from "~/components/ui/SquareCta"
import type { SliceWithProps } from "~/components/slices/SliceManager"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { usePanel } from "~/managers/PanelManager"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

const IMAGE_SIZES = [{ ratio: 1 }]

export type THeroBanner = SliceWithProps<{
  title?: Nullish<SbRichtext | string>
  text?: Nullish<SbRichtext | string>
  isFullWidth?: boolean
  image?: Nullish<ImageProps>
  textSize?: "small" | "medium" | "large"
  withLogin?: boolean
  returnTo?: Nullish<string>
  returnToCurrentPage?: boolean
  cta?: Nullish<TLinkLabel>
}>

export type HeroBannerProps = { isCollection?: boolean; withTopPadding?: boolean } & THeroBanner &
  RecipeVariants<typeof css.themeRecipe> &
  RecipeVariants<typeof css.headlineRecipe>

function HeroBanner({
  className,
  title,
  text,
  image,
  withLogin,
  isFullWidth = true,
  isFirstSlice = false,
  withTopPadding = false,
  theme,
  isCollection = false,
  textSize,
  font,
  returnTo,
  returnToCurrentPage,
  cta,
}: HeroBannerProps) {
  const hasImage = Boolean(image)
  const titleIsString = typeof title === "string"
  const textIsString = typeof text === "string"
  const hasTitle = titleIsString ? title?.length > 0 : isRTFilled(title)
  const hasText = textIsString ? text?.length > 0 : isRTFilled(text)
  const pathname = usePathname()
  const { data: customer } = useAuthentication()
  const descriptionRef = useRef<HTMLDivElement>(null)
  const [truncateDescription, setTruncateDescription] = useState(true)

  useEffect(() => {
    if (descriptionRef?.current) {
      // Check if the line clamping is active

      return setTruncateDescription(
        Number(descriptionRef?.current?.clientHeight) > 0 &&
          descriptionRef.current.scrollHeight > descriptionRef?.current?.clientHeight
      )
    } else {
      setTruncateDescription(false)
    }
  }, [descriptionRef.current])

  const { href } = cta ?? {}
  const processedReturnTo = returnToCurrentPage ? pathname : returnTo ?? href

  const t = useTranslate()
  const { add } = usePanel()

  if (!hasImage && !hasTitle && !hasText) return null

  return (
    <div
      data-comp="Slices/HeroBanner"
      className={clsx(className, sprinkles({ position: "relative" }), css.HeroBanner({ isFullWidth, withTopPadding }))}
    >
      {image && (
        <Image
          className={clsx(css.image)}
          imageClassName={clsx(css.img)}
          {...image}
          ratio={{ mobile: "375_404", tablet: "1440_580" }}
          sizesFromBreakpoints={IMAGE_SIZES}
          priority={isFirstSlice}
        />
      )}
      {(hasTitle || hasText) && (
        <div className={clsx(css.container, hasImage ? sprinkles({ color: "white" }) : sprinkles({ color: "black" }))}>
          {titleIsString ? (
            <h1
              className={clsx(
                css.headline,
                isCollection
                  ? css.headlineCollection({ isCollection, font })
                  : css.headlineRecipe({ font, size: textSize }),
                css.themeRecipe({ theme: hasImage ? theme : "black" })
              )}
            >
              {title}
            </h1>
          ) : (
            <RichText
              disableStyles
              className={clsx(
                css.headline,
                isCollection
                  ? css.headlineCollection({ isCollection, font })
                  : css.headlineRecipe({ font, size: textSize }),
                css.themeRecipe({ theme: hasImage ? theme : "black" })
              )}
              render={title}
            />
          )}
          {text && (
            <div className={css.descriptionContainer}>
              {textIsString ? (
                <HtmlRichText
                  ref={descriptionRef}
                  className={clsx(
                    css.description({ truncate: truncateDescription }),
                    css.themeRecipe({ theme: hasImage ? theme : "black" })
                  )}
                >
                  {text}
                </HtmlRichText>
              ) : (
                isRTFilled(text) && (
                  <div ref={descriptionRef} className={css.description({ truncate: truncateDescription })}>
                    <RichText
                      disableStyles
                      className={clsx(css.themeRecipe({ theme: hasImage ? theme : "black" }))}
                      render={text}
                    />
                  </div>
                )
              )}
              {truncateDescription && (
                <InlineCta
                  className={css.truncatedCta}
                  color={hasImage ? theme : "black"}
                  withLine
                  withHover={false}
                  withDefaultLine
                  size="small"
                  onClick={() => setTruncateDescription(false)}
                >
                  {t("cta_read_more")}
                </InlineCta>
              )}
            </div>
          )}
          {withLogin && !customer && (
            <SquareCta
              className={css.cta}
              theme="backgroundBlack"
              onClick={() => add(<LoginPanel {...(processedReturnTo ? { returnTo: processedReturnTo } : null)} />)}
              aria-label={t("account_log_in")}
            >
              {t("account_log_in")}
            </SquareCta>
          )}
          {(customer || !withLogin) && cta && <SquareCta className={css.cta} theme="backgroundBlack" {...cta} />}
        </div>
      )}
    </div>
  )
}

export default HeroBanner
