"use client"

import { useState, type PropsWithChildren } from "react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { env } from "~/env"
import { LazyMotion } from "framer-motion"
import { Provider as JotaiProvider } from "jotai"

import type {
  ShopifySFCountryCode,
  ShopifySFCurrencyCode,
  ShopifySFLanguageCode,
} from "@unlikelystudio/commerce-connector"
import { AlertProvider } from "@unlikelystudio/react-abstract-components"
import { AnalyticsProvider, CartProvider, CustomerProvider, StoreProvider } from "@unlikelystudio/react-ecommerce-hooks"
import { useFixScrollbarOverflow, useStableVh, useVh } from "@unlikelystudio/react-hooks"

import type { InferReturn } from "~/@types/generics"
import getCurrency from "~/lib/get-currency"
import { getCountry, getLang } from "~/lib/i18n/utils/get-i18n"
import { geti18nLocale, getProcessedLocale } from "~/lib/i18n/utils/get-processed-locale"
import { getStorePublicCredentials } from "~/lib/shopify/client/public"
import { productVariantMetafields } from "~/lib/shopify/constants"
import { LOCATIONS_COUNT } from "~/lib/shopify/constants/locations"
import { getLocalizedMetafields } from "~/lib/shopify/utils/get-localized-metafields"
import type { getGlobalsProps } from "~/data/globals/get-globals-props"
import useIsIOS from "~/hooks/useIsIOS"
import useLocale from "~/hooks/useLocale"
import { CustomAlerts } from "~/components/ui/Alerts"
import { CartEditoProvider } from "~/providers/CartProvider"
import { GridLayoutProvider } from "~/providers/GridLayoutProvider/index.client"
import { EventsBufferHandler } from "~/providers/GTMTrackingProvider/EventsBufferHandler"
import { InternalRedirectsProvider } from "~/providers/InternalRedirectsProvider/index.client"
import { GoogleReCaptchaProvider } from "~/providers/RecaptchaProvider/google-recaptcha-provider"
import { RecentlyViewedProvider } from "~/providers/RecentlyViewedProvider"
import { ShopifyAnalyticsWrapper as ShopifyAnalytics } from "~/providers/ShopifyAnalytics"
import { CardPopinManager } from "~/managers/CardPopinManager"
import { PanelManager } from "~/managers/PanelManager"
import { PopinManager } from "~/managers/PopinManager"

const motionAsyncFeatures = () =>
  import("~/providers/ClientAppProvider/utils/motion-features").then((res) => res.default)

type ClientGlobalProviderProps = PropsWithChildren<{
  contexts: Pick<InferReturn<typeof getGlobalsProps>, "internalRedirects" | "cart">
}>

export function ClientAppProvider({ children, contexts }: ClientGlobalProviderProps) {
  useIsIOS()
  useStableVh()
  useVh()
  useFixScrollbarOverflow()

  const locale = useLocale()

  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
    })
  )

  const shopifyCredential = getStorePublicCredentials()

  const recaptchaScriptProps = {
    async: true,
    defer: true,
    appendTo: "body",
  } as const

  if (!shopifyCredential?.storeUrl || !shopifyCredential?.storefrontAccessToken) {
    throw Error(`Missing shop credentials ${shopifyCredential}`)
  }

  return (
    <StoreProvider
      storeUrl={shopifyCredential.storeUrl}
      storefrontAccessToken={shopifyCredential.storefrontAccessToken}
      locale={getProcessedLocale(locale)}
      country={getCountry(locale).toUpperCase() as ShopifySFCountryCode}
      currency={getCurrency(geti18nLocale(locale)) as ShopifySFCurrencyCode}
      language={getLang(locale).toUpperCase() as ShopifySFLanguageCode}
    >
      <GoogleReCaptchaProvider reCaptchaKey={env.NEXT_PUBLIC_RECAPTCHA_KEY} scriptProps={recaptchaScriptProps}>
        <QueryClientProvider client={queryClient}>
          <AnalyticsProvider>
            <InternalRedirectsProvider redirects={contexts?.internalRedirects}>
              <CartEditoProvider cart={contexts?.cart}>
                <CartProvider
                  customCookieName="shopify-cart"
                  keepCartOnLocaleChange={false}
                  cartInput={{
                    storeAvailabilityFirst: LOCATIONS_COUNT,
                    metafieldVariantsKeys: Object.values(getLocalizedMetafields(productVariantMetafields, locale)),
                    includeQuantityAvailable: true,
                  }}
                >
                  <CustomerProvider>
                    <JotaiProvider>
                      <LazyMotion strict features={motionAsyncFeatures}>
                        <AlertProvider>
                          <RecentlyViewedProvider>
                            <GridLayoutProvider>{children}</GridLayoutProvider>
                          </RecentlyViewedProvider>
                          <PanelManager />
                          <EventsBufferHandler />
                          <PopinManager />
                          <CardPopinManager layout />
                          <CustomAlerts />
                        </AlertProvider>
                      </LazyMotion>
                      <ShopifyAnalytics />
                    </JotaiProvider>
                  </CustomerProvider>
                </CartProvider>
              </CartEditoProvider>
            </InternalRedirectsProvider>
          </AnalyticsProvider>
        </QueryClientProvider>
      </GoogleReCaptchaProvider>
    </StoreProvider>
  )
}
