import { sfProductsByIds } from "@unlikelystudio/commerce-connector"

import { productMetafields, productVariantMetafields } from "~/lib/shopify/constants"
import { LOCATIONS_COUNT } from "~/lib/shopify/constants/locations"
import { getLocalizedMetafields } from "~/lib/shopify/utils/get-localized-metafields"
import { sfFetchWrapper } from "~/lib/shopify/utils/sf-fetch-wrapper"
import { objectValues } from "~/utils/object-values"

export type IGetShopifyProductsByIds = Awaited<ReturnType<typeof getSfProductsByIds>>

export type IGetShopifyProductsByIdsParams = Parameters<typeof get>

export async function getSfProductsByIds(...params: IGetShopifyProductsByIdsParams) {
  const [input, options] = params

  return await get(
    {
      includePrices: true,
      variantsFirst: 100,
      collectionsFirst: 100,
      includeDescriptionHtml: true,
      imagesFirst: 100,
      includeSeo: true,
      includeFeaturedImage: false,
      includeSelectedOptions: true,
      includeTags: true,
      includeQuantityAvailable: true,
      metafieldVariantsKeys: objectValues(getLocalizedMetafields(productVariantMetafields, input.locale)),
      metafieldKeys: objectValues(getLocalizedMetafields(productMetafields, input.locale)),
      mediaFirst: 100,
      storeAvailabilityFirst: LOCATIONS_COUNT,
      ...input,
    },
    options
  )
}

const get = sfFetchWrapper(sfProductsByIds)("product")
