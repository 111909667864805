import clsx from "clsx"

import type { SliderNavigationButtonProps } from "~/components/ui/SliderNavigation/types/button"
import Icon from "~/components/abstracts/Icon"

import * as css from "./styles.css"

export function ChevronButton({ onClick, className, ariaLabel, direction }: SliderNavigationButtonProps) {
  return (
    <button
      className={clsx(css.button, className)}
      onClick={() => {
        onClick?.()
      }}
      aria-label={ariaLabel}
    >
      <Icon className={clsx(css.icon)} name="Chevron" {...(direction ? { direction } : {})} />
    </button>
  )
}
