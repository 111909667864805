"use client"

import type { Nullish } from "~/@types/generics"
import SquareCta from "~/components/ui/SquareCta"
import Icon from "~/components/abstracts/Icon"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import * as css from "./styles.css"

export default function Auth0Buttons({ returnTo }: { returnTo?: Nullish<string> }) {
  const t = useTranslate()

  return (
    <div className={css.Auth0Buttons}>
      <SquareCta
        href={`/api/auth/login-with-google${returnTo ? `?returnTo=${returnTo}` : ""}`}
        className={css.SSOButton}
        fill
      >
        <Icon name={"AuthGoogle"} direction="right" className={css.icon} />
        <span>{t("account_login_with", { provider: "Google" })}</span>
      </SquareCta>

      <SquareCta
        href={`api/auth/login-with-facebook${returnTo ? `returnTo=${returnTo}` : ""}`}
        className={css.SSOButton}
        fill
      >
        <Icon name={"AuthFacebook"} direction="right" className={css.icon} />
        <span>{t("account_login_with", { provider: "Facebook" })}</span>
      </SquareCta>
    </div>
  )
}
