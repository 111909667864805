"use client"

import { type MouseEvent } from "react"

import type { InferReturn, PropsWithClassName } from "~/@types/generics"
import BackInStockPopin from "~/components/ui/BackInStockPopin"
import type { serializeSfBackInStock } from "~/components/ui/BackInStockPopin/_data/serializer"
import SquareCta from "~/components/ui/SquareCta"
import { ClientTranslate } from "~/providers/I18nProvider/ClientTranslate"
import { usePopin } from "~/managers/PopinManager"

import { text } from "~/styles/variants"

export function BackInStockButton({
  className,
  backInStock,
}: PropsWithClassName<{ backInStock: InferReturn<typeof serializeSfBackInStock> }>) {
  const { add: addPopin } = usePopin()

  // Events
  const onClickStockAlert = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (backInStock) {
      addPopin(<BackInStockPopin {...backInStock} />)
    }
  }

  return (
    <div className={className}>
      <SquareCta
        className={text({ design: "neue-10-12" })}
        overrideTextPresets
        theme="backgroundBlack"
        onClick={onClickStockAlert}
      >
        <ClientTranslate tKey="stock_alert" />
      </SquareCta>
    </div>
  )
}
