import { createContext, useContext, useState, type Dispatch, type PropsWithChildren, type SetStateAction } from "react"
import { useQueries, type UseQueryOptions } from "@tanstack/react-query"

import type { GID } from "@unlikelystudio/commerce-connector"

import type { InferReturn } from "~/@types/generics"
import { productMetafields, productVariantMetafields } from "~/lib/shopify/constants/metafields"
import { getSfProduct } from "~/lib/shopify/queries/get-sf-product"
import { getLocalizedMetafields } from "~/lib/shopify/utils/get-localized-metafields"
import { isGID } from "~/lib/shopify/utils/id"
import useLocale from "~/hooks/useLocale"
import { PRODUCT_ROOT_FIELDS, type ProductCardRevampedProps } from "~/components/ui/ProductCardRevamped/index.client"
import { objectValues } from "~/utils/object-values"

type ProductCardSiblingsContextProps = {
  currentProductId: GID | null
  setCurrentProductId: Dispatch<SetStateAction<GID | null>>
}

const ProductCardSiblingsContext = createContext<ProductCardSiblingsContextProps>({
  currentProductId: null,
  setCurrentProductId: () => {},
})

export function useProductCardSiblings() {
  const context = useContext(ProductCardSiblingsContext) ?? []

  if (!context) {
    throw new Error("useProductCardSiblings must be used within a ProductCardSiblingsProvider")
  }

  return context
}

export const PRODUCT_SIBLING_QUERY_KEY = "get-sibling-product"

export function ProductCardSiblingsProvider({
  children,
  product,
  siblings,
}: PropsWithChildren<Pick<ProductCardRevampedProps, "product" | "siblings">>) {
  const locale = useLocale()
  const [currentProductId, setCurrentProductId] = useState<GID | null>(product?.id)

  const validSiblings = siblings?.filter((sibling) => isGID(sibling?.id)) ?? []

  useQueries<
    UseQueryOptions<
      InferReturn<typeof getSfProduct>,
      unknown,
      InferReturn<typeof getSfProduct>,
      [typeof PRODUCT_SIBLING_QUERY_KEY, GID]
    >[]
  >({
    queries:
      validSiblings?.map((sibling) => ({
        queryKey: [PRODUCT_SIBLING_QUERY_KEY, sibling?.id as GID],
        queryFn: async () => {
          return await getSfProduct({
            id: sibling.id as GID,
            locale,
            metafieldKeys: objectValues(getLocalizedMetafields(productMetafields, locale)),
            metafieldVariantsKeys: objectValues(getLocalizedMetafields(productVariantMetafields, locale)),
            ...PRODUCT_ROOT_FIELDS,
            withLocale: true,
          })
        },
        enabled: false,
        staleTime: 1000,
        keepPreviousData: true,
        refetchOnMount: false,
      })) ?? [],
  })

  return (
    <ProductCardSiblingsContext.Provider
      value={{
        currentProductId,
        setCurrentProductId,
      }}
    >
      {children}
    </ProductCardSiblingsContext.Provider>
  )
}
