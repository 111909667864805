import type { UnlikelyCartLineInput } from "@unlikelystudio/commerce-connector"

import { PRODUCT_CUSTOM_ATTRIBUTES } from "~/lib/shopify/constants"
import type { UnlikelyCartLine } from "~/components/ui/CartPanel"

export function persistAttributesOnChange(attributes: UnlikelyCartLine["attributes"]) {
  // Retrieve existing line attributes apart from those that will be updated
  return (
    ((attributes ?? [])?.filter((attribute) => {
      if (!attribute?.key) return false
      // exclude tracking data and thumbnail from attributes to persist
      return ![PRODUCT_CUSTOM_ATTRIBUTES.TRACKING_DATA, PRODUCT_CUSTOM_ATTRIBUTES.THUMBNAIL]?.includes(attribute?.key)
    }) as UnlikelyCartLineInput["attributes"]) ?? []
  )
}
