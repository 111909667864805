import type { CardMediaProps } from "~/components/ui/ProductCardRevamped/CardContent/ScrollSnapGallery/index.client"

export type RhythmItem = (typeof RHYTHM_ITEMS)[number]
export type RhythmItems = RhythmItem[]

const RHYTHM_ITEMS = ["packshot", "american", "worn"] as const

/* The `const IMAGE_SUFFIXES_BY_RHYTHM` is a constant object that maps each `RhythmItem` value to a
specific string suffix. In this case, it associates the `packshot` item with the suffix "-1",
`american` with "-h", and `worn` with "-5". This mapping allows the code to determine the suffix to
look for when searching for a specific image type within the filenames of images in the `images`
array. */
const IMAGE_SUFFIXES_BY_RHYTHM: Record<RhythmItem, string> = {
  packshot: "-1",
  american: "-h",
  worn: "-5",
}

/**
 * The function `findImageIndexByImageType` searches for the index of an image in an array based on its
 * type.
 * @param {CardMediaProps[]} images - The `images` parameter is an array of `CardMediaProps` objects,
 * which likely contain information about images such as their source, dimensions, and other
 * properties.
 * @param {RhythmItem} imageType - RhythmItem
 * @returns The function `findImageIndexByImageType` returns the index of the first image in the
 * `images` array that matches the image type specified by `imageType`. If no matching image is found,
 * it returns -1.
 */
function findImageIndexByImageType(images: CardMediaProps[], imageType: RhythmItem) {
  return images?.filter(Boolean)?.findIndex((image) => {
    const filename = image?.data?.src?.split("/")?.reverse()?.[0]?.split(".")?.[0]

    return filename?.endsWith(IMAGE_SUFFIXES_BY_RHYTHM[imageType])
  })
}

/**
 * The function `reorderImages` reorders an array of images based on a specified image type, moving the
 * matching image to the front of the array.
 * @param {CardMediaProps[]} images - The `reorderImages` function takes in an array of `images` and an
 * `imageType` as parameters. The `images` parameter is an array of CardMediaProps objects, which
 * likely contain information about images such as URLs, alt text, and other properties. The
 * `imageType`
 * @param {RhythmItem} imageType - The `imageType` parameter in the `reorderImages` function is used to
 * specify the type of image that needs to be reordered within the array of images. It is of type
 * `RhythmItem`.
 * @returns If the `imageType` is found in the `images` array, the function will return a new array
 * with the image of that type moved to the beginning. If the `imageType` is not found but a default
 * "packshot" image is found, the function will return a new array with the packshot image moved to the
 * beginning. If neither the `imageType` nor a default
 */
export function reorderImages(images: CardMediaProps[], imageType: RhythmItem) {
  const clonedImages = [...images]

  const foundImage = findImageIndexByImageType(clonedImages, imageType)

  if (foundImage !== -1) {
    const firstImage = clonedImages?.splice(foundImage, 1)
    return [...firstImage, ...clonedImages]
  }

  const foundDefaultWornImage = findImageIndexByImageType(clonedImages, "worn")

  if (foundDefaultWornImage !== -1) {
    const firstWornImage = clonedImages?.splice(foundDefaultWornImage, 1)
    return [...firstWornImage, ...clonedImages]
  }

  return clonedImages
}
