import { useEffect } from "react"
import { useRouter } from "next/navigation"

import { useGetCustomer } from "@unlikelystudio/react-ecommerce-hooks"

export default function useAuthentication(redirectOnError?: string, redirectOnSuccess?: string) {
  const router = useRouter()

  useEffect(() => {
    redirectOnError && router.prefetch(redirectOnError as string)
    redirectOnSuccess && router.prefetch(redirectOnSuccess as string)
  }, [])

  const profileQuery = useGetCustomer({
    refetchOnMount: true,
    keepPreviousData: true,
    retry: 0,

    onSuccess: (data) => {
      data && redirectOnSuccess && router.replace(redirectOnSuccess)
      !data && redirectOnError && router.replace(redirectOnError)
    },
  })

  const shouldRedirectOnError = profileQuery.fetchStatus === "idle" && !profileQuery.data && redirectOnError

  useEffect(() => {
    if (shouldRedirectOnError) {
      redirectOnError && router.replace(redirectOnError)
    }
  }, [shouldRedirectOnError])

  return profileQuery
}
