"use client"

import { useMemo, useState, type ComponentProps, type MouseEvent } from "react"
import Link, { type LinkProps } from "next/link"
import { useRouter } from "next/navigation"
import { clsx } from "clsx"

import type { UnlikelyProduct } from "@unlikelystudio/commerce-connector"
import { useGetCustomer } from "@unlikelystudio/react-ecommerce-hooks"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import { formatCheckoutItems } from "~/lib/shopify/utils/format-checkout-items"
import { getDefaultVariant } from "~/lib/shopify/utils/get-default-variants"
import { useAddToCart } from "~/hooks/useAddToCart"
import useLocale from "~/hooks/useLocale"
import { useSerializeOptions } from "~/components/ui/CartPanel/hooks/serialize-options-from-variants"
import SizeSelect from "~/components/ui/CartPanel/SizeSelect"
import { FillImage } from "~/components/ui/FillImage"
import serializeImage from "~/components/ui/Image/_data/serializer"
import InlineCta from "~/components/ui/InlineCta"
import Price from "~/components/ui/Price"
import { serializeSfPrice } from "~/components/ui/Price/_data/serializer"
import { serializeVariants } from "~/components/ui/ProductHeader/_data/serialize-variant"
import type { TVariant } from "~/components/ui/ProductHeader/_data/types"
import type { CartLineItemPayload } from "~/providers/GTMTrackingProvider/constants"
import { getProductTrackingDataWithCollections } from "~/providers/GTMTrackingProvider/utils/get-product-tracking-data-with-collections"
import { variantToTrackingData } from "~/providers/GTMTrackingProvider/utils/variant-to-tracking-data"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { getPackshotFromImages } from "~/utils/get-packshot-from-image"
import { processVpPrice } from "~/utils/vp-prices/process-vp-prices"

import * as css from "./styles.css"

export type CardProps = PropsWithClassName<
  {
    id?: Nullish<string>
    handle?: Nullish<string>
    link: LinkProps
    product: UnlikelyProduct
    collectionsHandles: string[]
    trackingData: CartLineItemPayload
  } & ComponentProps<"div">
>

export function CompleteTheLookCard({ className, product, link, trackingData, onClick, ...rest }: CardProps) {
  const router = useRouter()
  const locale = useLocale()
  const t = useTranslate()

  const variants = serializeVariants(product?.variants, locale)
  const defaultVariant = getDefaultVariant(variants, product)
  const [activeVariant, setVariant] = useState<Nullish<TVariant>>(defaultVariant)
  const { data: customer } = useGetCustomer()
  const selectsOptions = useSerializeOptions(product?.variants)

  const processedTrackingData = {
    ...getProductTrackingDataWithCollections(trackingData, product, locale),
    ...(activeVariant ? variantToTrackingData(activeVariant) ?? {} : {}),
  }

  const checkoutItems = useMemo(() => {
    if (!activeVariant) return []
    return formatCheckoutItems({ variant: activeVariant, trackingData: processedTrackingData })
  }, [activeVariant?.id])

  const { mutate: addCartLines } = useAddToCart({ id: activeVariant?.id }, undefined, false)
  if (!product.variants || !product) return null
  const price = defaultVariant?.price
  const compareAtPrice = defaultVariant?.compareAtPrice
  const vpPrice = defaultVariant?.vpPrice

  const title = product.title
  const images = product.images.map((image) => serializeImage(image, locale)).filter(Boolean)
  const { image, isPackshot } = getPackshotFromImages(images)

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    onClick?.(e)
    router.push(link.href.toString())
  }

  const { processedPrice, processedCompareAtPrice } = processVpPrice({ price, compareAtPrice, vpPrice, customer })

  const sfPrice = serializeSfPrice(locale, processedPrice, processedCompareAtPrice)

  return (
    <div className={clsx(css.Card, className)} {...rest}>
      {image && (
        <FillImage
          onClick={handleClick}
          className={css.image}
          withBackground={isPackshot}
          imageClassName={isPackshot ? css.objectFitContain : undefined}
          {...image}
          ratio="74_110"
          asPlaceholder
        />
      )}
      <div className={clsx(css.footer)}>
        <Link className={clsx(css.title)} {...link}>
          {title}
        </Link>
        {sfPrice && <Price className={css.price} {...sfPrice} />}
        <div className={css.selects}>
          {selectsOptions?.map(([key, value]) => (
            <SizeSelect
              key={key}
              defaultValue={activeVariant?.id ?? undefined}
              name={key}
              options={value}
              disableBorders
              onChange={(e) => {
                const newVariantId = e?.target?.value
                const newVariant = variants.find((v) => v.id === newVariantId)
                setVariant(newVariant)
              }}
            />
          ))}
        </div>
        <InlineCta className={css.cta} withDefaultLine onClick={() => addCartLines(checkoutItems)}>
          {t("add_to_cart")}
        </InlineCta>
      </div>
    </div>
  )
}
