import { env } from "~/env"

import type { Nullish } from "~/@types/generics"
import type { SortBy } from "~/lib/algolia/types"
import { isReplicaSuffix } from "~/lib/algolia/utils/is-replica-suffix"
import type { I18nLocale } from "~/lib/i18n/types"
import { getMarket } from "~/lib/i18n/utils/get-i18n"

export function getIndexName({ locale, suffix }: { suffix?: SortBy; locale: I18nLocale }) {
  if (!locale) {
    throw new Error("missing required parameter")
  }

  const isFranceMarket = getMarket(locale) === "France"
  const indexName = `${env.NEXT_PUBLIC_BRAND_NAME}_${locale?.toLowerCase()}_main${
    isFranceMarket ? "" : "_inter"
  }_products`

  if (isReplicaSuffix(suffix)) {
    return getReplicaIndexName({ index: indexName, suffix })
  }

  return indexName
}

export function getReplicaIndexName({ index, suffix }: { index: string; suffix: Nullish<SortBy> }) {
  return `${index.replace("_main", "_replica")}_${suffix}`
}
