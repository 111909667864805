import { parseAsString, useQueryState } from "nuqs"

export function useSearchQueryParam(): {
  searchQueryParam: string
  setSearchQueryParam: (value: string) => void
} {
  const [searchQueryParam, setSearchQueryParam] = useQueryState(
    "q",
    parseAsString.withOptions({ clearOnDefault: true, shallow: true }).withDefault("")
  )

  return { searchQueryParam, setSearchQueryParam }
}
