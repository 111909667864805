import { env } from "~/env"
import algoliasearch from "algoliasearch"
import algoliaSearchInsights from "search-insights"

import { getIndexName } from "~/lib/algolia/utils/get-index-name"

export const algoliaPublicClient = algoliasearch(env.NEXT_PUBLIC_ALGOLIA_APP_ID, env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY)

export function getAlgoliaPublicIndex(params: Parameters<typeof getIndexName>[0]) {
  const indexName = getIndexName(params)
  return algoliaPublicClient.initIndex(indexName)
}

export { algoliaSearchInsights }
