import { useEffect, useState } from "react"

import { ALGOLIA_QUERY_ID_LOCAL_STORAGE_KEY } from "~/lib/algolia/constants"

export default function useAlgoliaQueryId() {
  const [algoliaQueryId, setAlgoliaQueryId] = useState("")

  useEffect(() => {
    if (typeof window === "undefined") return

    const localAlgoliaQueryId = localStorage.getItem(ALGOLIA_QUERY_ID_LOCAL_STORAGE_KEY)
    setAlgoliaQueryId(localAlgoliaQueryId ?? "")
  }, [])

  return algoliaQueryId
}
