"use client"

import { ALERT_CODES } from "~/lib/alert"
import { useAlerts } from "~/hooks/useAlerts"
import { AccountPanel } from "~/components/ui/Panels/AccountPanel"
import { content as contentStyle } from "~/components/ui/Panels/AccountPanel/style.css"
import LoginPanel from "~/components/ui/Panels/Login"
import { SubFormMentions } from "~/components/ui/SubFormMentions"
import RegisterFormTemplate from "~/components/forms/RegisterForm"
import { INPDodger } from "~/components/shared/INPDodger/index.client"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { usePanel } from "~/managers/PanelManager"

function RegisterPanel({}) {
  const { add, removeCurrent } = usePanel()

  const triggerAlert = useAlerts()

  const t = useTranslate()

  return (
    <AccountPanel headerTitle={t("account_register_panel_title")}>
      <div className={contentStyle}>
        <RegisterFormTemplate
          onLoginSuccess={() => {
            removeCurrent()
            triggerAlert(ALERT_CODES.LOGGED_IN)
          }}
        />
        <INPDodger>
          {({ cn, onTransition }) => (
            <SubFormMentions
              title={t("account_already_have_account")}
              ctaProps={{
                className: cn,
                onClick: () => {
                  onTransition(() => {
                    removeCurrent()
                    add(<LoginPanel />)
                  })
                },
              }}
            >
              {t("account_log_in")}
            </SubFormMentions>
          )}
        </INPDodger>
      </div>
    </AccountPanel>
  )
}

export default RegisterPanel
