"use client"

import { Children, Fragment, useEffect, type ComponentProps, type PropsWithChildren } from "react"
import { clsx } from "clsx"
import { type EmblaCarouselType, type EmblaOptionsType } from "embla-carousel"
import useEmblaCarousel from "embla-carousel-react"
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures"

import type { PropsWithClassName } from "~/@types/generics"
import { AbstractLink, type AbstractLinkProps } from "~/components/abstracts/Link"
import { EmblaSliderControls } from "~/components/shared/EmblaSlider/Controls/index.client"
import { EmblaSliderStepper } from "~/components/shared/EmblaSlider/Stepper/index.client"

import * as css from "./styles.css"

export type EmblaSliderProps = Partial<Pick<ComponentProps<typeof EmblaSliderControls>, "controlsStyle">> & {
  containerClassName?: string
  controlsClassName?: string
  controlButtonClassName?: string
  displayControlsOnHover?: boolean
  link?: Required<Pick<AbstractLinkProps, "href"> & { "aria-label": string }>
  options?: EmblaOptionsType
  hideOverflow?: boolean
  sliderClassName?: string
  withStepper?: boolean
  buttonStyle?: "bordered" | "cover"
  buttonType?: "arrow" | "chevron"
  onSlideChange?: (index: number) => void
}

/**
 * ! this component is not used at the moment
 * ! but could be in the future if we decide to switch all sliders
 */
export function EmblaSlider({
  className,
  children,
  containerClassName,
  controlsStyle,
  displayControlsOnHover,
  controlsClassName,
  controlButtonClassName,
  buttonStyle = "bordered",
  buttonType = "chevron",
  link,
  options,
  hideOverflow = false,
  sliderClassName,
  withStepper,
  onSlideChange,
}: PropsWithClassName<PropsWithChildren<EmblaSliderProps>>) {
  const slidesCount = Children?.count?.(children)
  const [sliderRef, emblaApi] = useEmblaCarousel({ ...options, active: slidesCount > 1, skipSnaps: true }, [
    WheelGesturesPlugin({ forceWheelAxis: "x" }),
  ])

  function onSliderChange(slider: EmblaCarouselType) {
    const activeIndex = slider?.selectedScrollSnap?.()
    onSlideChange?.(activeIndex)
  }

  useEffect(() => {
    if (!emblaApi) return
    onSliderChange(emblaApi)
    emblaApi.on("reInit", onSliderChange).on("select", onSliderChange)
  }, [emblaApi, onSliderChange])

  const asLink = Boolean(link?.href)
  const Tag = asLink ? AbstractLink : "div"

  return (
    <div className={clsx(className, css.slider)}>
      <div
        ref={sliderRef}
        className={clsx(containerClassName, css.container, hideOverflow ? css.hideOverflow : css.showOverflow)}
      >
        <Tag className={clsx(sliderClassName, css.slides)} {...(asLink && link)}>
          {children}
        </Tag>
      </div>
      {slidesCount > 1 && (
        <Fragment>
          {withStepper && <EmblaSliderStepper emblaApi={emblaApi} length={Children?.count?.(children)} />}
          {controlsStyle && (
            <EmblaSliderControls
              controlClassName={controlButtonClassName}
              className={clsx(displayControlsOnHover && css.controlsOnHover, controlsClassName)}
              controlsStyle={controlsStyle}
              emblaApi={emblaApi}
              length={Children?.count?.(children)}
              buttonStyle={buttonStyle}
              buttonType={buttonType}
            />
          )}
        </Fragment>
      )}
    </div>
  )
}
