"use client"

import { createContext, useMemo, type PropsWithChildren } from "react"

import { COOKIE_KEYS } from "~/lib/constants/cookies"
import useCurrency from "~/hooks/useCurrency"
import { CookieProps } from "~/providers/RecentlyViewedProvider/utils/types"

export const RECENTLY_VIEWED_LENGTH = 8

const defaultValues = {
  expireDays: 7,
  maxProducts: RECENTLY_VIEWED_LENGTH,
  cookieAge: 365,
  cookieKey: COOKIE_KEYS.RECENTLY_VIEWED,
}

export const RecentlyViewedContext = createContext(defaultValues)

type ProviderProductsViewedProps = PropsWithChildren<{ cookieProps?: CookieProps }>

export function RecentlyViewedProvider({ cookieProps = {}, children }: ProviderProductsViewedProps) {
  const currency = useCurrency()
  const { expireDays, maxProducts, cookieAge, cookieKey } = cookieProps

  const values = useMemo(
    () => ({
      ...defaultValues,
      cookieKey: `${defaultValues.cookieKey}-${currency}`,
      ...cookieProps,
    }),
    [expireDays, maxProducts, cookieAge, cookieKey]
  )

  return <RecentlyViewedContext.Provider value={values}>{children}</RecentlyViewedContext.Provider>
}
