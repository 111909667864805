"use client"

import { useEffect, useState } from "react"
import clsx from "clsx"
import { type EmblaCarouselType } from "embla-carousel"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import ArrowButton from "~/components/ui/SliderNavigation/components/ArrowButton"
import { ChevronButton } from "~/components/ui/SliderNavigation/components/ChevronButton"
import { type IconProps } from "~/components/abstracts/Icon"
import type { EmblaSliderProps } from "~/components/shared/EmblaSlider/index.client"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import * as css from "./styles.css"

type EmblaSliderControlsProps = PropsWithClassName<{
  // for now controls style has no use
  // but once new sliders are implemented, we'll need to make use of it
  // as of now, centered style is considered as light arrows centered vertically within the slides
  controlsStyle: "centered" | "top-right"
  emblaApi: Nullish<EmblaCarouselType>
  length: number
  buttonClassName?: string
  controlClassName?: string
  buttonType?: EmblaSliderProps["buttonType"]
  buttonStyle?: EmblaSliderProps["buttonStyle"]
}>

type EmblaSliderButtonProps = {
  onClick(): void
  ariaLabel: string
  direction?: IconProps["direction"]
}

function getButtonFromType(type: EmblaSliderControlsProps["buttonType"], props: EmblaSliderButtonProps) {
  switch (type) {
    case "chevron":
      return <ChevronButton {...props} />
    case "arrow":
      return <ArrowButton {...props} />
  }
}

export function EmblaSliderControls({
  className,
  emblaApi,
  length,
  buttonType,
  buttonStyle,
  controlClassName,
  controlsStyle,
}: EmblaSliderControlsProps) {
  const t = useTranslate()
  const [previousButtonDisabled, setPreviousButtonDisabled] = useState(false)
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false)

  const { scrollPrev, scrollNext } = emblaApi ?? {}

  function onSliderChange(slider: EmblaCarouselType) {
    setPreviousButtonDisabled(!slider?.canScrollPrev?.())
    setNextButtonDisabled(!slider?.canScrollNext?.())
  }

  useEffect(() => {
    if (!emblaApi) return
    onSliderChange(emblaApi)
    emblaApi.on("reInit", onSliderChange).on("select", onSliderChange)
  }, [emblaApi, onSliderChange])

  if (!length) return null

  const nextButtonProps = {
    className: clsx(css.control({ direction: "next", disabled: nextButtonDisabled }), controlClassName),
    onClick: scrollNext,
    ariaLabel: t("aria_arrow_next"),
    direction: "right",
  } as EmblaSliderButtonProps

  const prevButtonProps = {
    className: clsx(css.control({ direction: "previous", disabled: previousButtonDisabled }), controlClassName),
    onClick: scrollPrev,
    ariaLabel: t("aria_arrow_prev"),
  } as EmblaSliderButtonProps

  const NextButton = getButtonFromType(buttonType, nextButtonProps)
  const PrevButton = getButtonFromType(buttonType, prevButtonProps)

  return (
    <div className={clsx(css.controls({ layout: controlsStyle, style: buttonStyle }), className)}>
      {PrevButton}
      {NextButton}
    </div>
  )
}
