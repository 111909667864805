import { useQuery, type UseQueryOptions } from "@tanstack/react-query"

import { useGetProduct } from "@unlikelystudio/react-ecommerce-hooks"

import { SHOPIFY_ADMIN_STOCK_PARAMS } from "~/lib/api/constants"
import { requestNextApiEndpoint } from "~/lib/api/utils/request-endpoint"
import { isDarel } from "~/lib/brand"
import useMarket from "~/hooks/useMarket"

const QUERY_KEY = "get_variant_inventory"

type QueryKey = [typeof QUERY_KEY, string[]]
type QueryFnData = {
  sku: string
  quantityAvailable: number | null
}[]

export function useGetVariantsInventory(
  queryOptions?: Pick<UseQueryOptions<QueryFnData, Error, QueryFnData, QueryKey>, "enabled">
) {
  const market = useMarket()

  const { data: product } = useGetProduct()
  const skus =
    product?.variants
      ?.map((variant) => variant?.sku)
      ?.filter(Boolean)
      ?.sort() ?? []

  const enabled = isDarel && market !== "France" && queryOptions?.enabled && skus?.length

  const queryState = useQuery<QueryFnData, Error, QueryFnData, QueryKey>({
    queryKey: ["get_variant_inventory", skus],
    queryFn: async () => {
      return await Promise.all(
        skus?.map(async (sku) => {
          const quantityAvailable = await requestNextApiEndpoint<number>(SHOPIFY_ADMIN_STOCK_PARAMS, {
            queryParams: { sku },
          })?.catch(() => null)

          return { sku, quantityAvailable }
        }) ?? []
      )
    },
    keepPreviousData: true,
    staleTime: 60,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(enabled),
  })

  return { ...queryState, enabled: Boolean(enabled) }
}
