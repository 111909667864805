import { notFound } from "next/navigation"

import {
  UnlikelyMediaType,
  type UnlikelyImage,
  type UnlikelyMedia,
  type UnlikelyProduct,
  type UnlikelyVideo,
} from "@unlikelystudio/commerce-connector"

import type { InferReturn, Nullish } from "~/@types/generics"
import { getMarket } from "~/lib/i18n/utils/get-i18n"
import { productMetafields } from "~/lib/shopify/constants"
import { getLocalizedMetafields } from "~/lib/shopify/utils/get-localized-metafields"
import { productHasStockAlert } from "~/lib/shopify/utils/product-utils"
import { serializeSfBackInStock } from "~/components/ui/BackInStockPopin/_data/serializer"
import serializeImage, { serializeImages } from "~/components/ui/Image/_data/serializer"
import serializeLink from "~/components/ui/Link/_data/serializer"
import { serializeShopTheLook, type ShopTheLook } from "~/components/ui/Panels/ShopTheLook/_data/serializer"
import type { TProduct } from "~/components/ui/ProductHeader/_data/types"
import serializeVariantsSelector from "~/components/ui/ProductHeader/components/ProductDetails/components/ProductVariantsSelector/_data/serializer"
import { serializeVideo, serializeVideos } from "~/components/ui/Video/_data/serializer"
import { unlikelyProductToTrackingData } from "~/providers/GTMTrackingProvider/utils/unlikely-product-to-tracking-data"
import { metafieldsToObject } from "~/utils/metafields-to-object"

export function isUnlikelyMediaImage(media: UnlikelyMedia): media is UnlikelyImage {
  return media?.mediaContentType === UnlikelyMediaType.Image
}

export function isUnlikelyMediaVideo(media: UnlikelyMedia): media is UnlikelyVideo {
  return media?.mediaContentType === UnlikelyMediaType.Video
}

function discriminateMedias(medias: UnlikelyMedia[]) {
  return {
    images: medias.filter(isUnlikelyMediaImage).filter(Boolean) ?? [],
    videos: medias.filter(isUnlikelyMediaVideo).filter(Boolean) ?? [].filter(Boolean) ?? [],
  }
}

export function serializeMedias(items: UnlikelyMedia[], locale: Nullish<string>) {
  return items.map((item) => serializeMedia(item, locale)).filter(Boolean)
}

export function serializeMedia(item: UnlikelyMedia, locale: Nullish<string>) {
  if (isUnlikelyMediaImage(item)) {
    return serializeImage(item, locale)
  }

  if (isUnlikelyMediaVideo(item)) {
    return serializeVideo(item)
  }

  return null
}

export function getProductType(product: UnlikelyProduct) {
  return product.tags.find((tag) => tag.includes("filter_apparel|"))?.split("|")[1] ?? product.productType ?? null
}

export function serializeProductHeaderSP(
  product: UnlikelyProduct,
  locale: string,
  shopTheLook?: ShopTheLook
): TProduct {
  const market = getMarket(locale)
  const metafields =
    product.metafields && metafieldsToObject(product.metafields, getLocalizedMetafields(productMetafields, locale))

  const { images, videos } = discriminateMedias(product.media)
  const serializedMedias = serializeMedias(product.media, locale)
  const serializedImages = [...serializeImages(images, locale), ...serializeImages(product.images, locale)]
  const serializedVideos = serializeVideos(videos) ?? []
  const serializedLink = serializeLink(product, locale)
  const productType = getProductType(product)

  return {
    handle: product.handle,
    id: product.id,
    title: product?.title ?? null,
    template: (metafields?.template ?? "default") as TProduct["template"],
    collectionsHandles: product.collections.map((collection) => collection.handle).filter(Boolean),
    stockAlertEnabled: productHasStockAlert(product),
    eReservationEnabled: market === "France" ? product.tags.indexOf("no_e_resa") === -1 : false,
    sizeGuideDisabled: Boolean(metafields?.no_size_guide === "true"),
    description: product.description ?? null,
    // use for bedding bundles
    isPillowCase: Boolean(product.tags.indexOf("taies_oreiller") !== -1),
    category: metafields?.apparel ?? null,
    productType,
    images: serializedImages,
    backInStock: serializeSfBackInStock(product, locale) ?? null,
    videos: serializedVideos,
    medias: serializedMedias,
    shopTheLookIds: shopTheLook?.flatMap((item) => item.id ?? []) ?? [],
    link: serializedLink,
    collections: product.collections,
    cardTags: metafields?.cardTags?.split(",") ?? null,
    metafields: product?.metafields ?? null,
    ...serializeVariantsSelector(product, locale),
  }
}

export default async function serializeProductHeader({
  product,
  locale,
}: {
  product: UnlikelyProduct
  locale: string
}) {
  if (!product) notFound()

  const metafields =
    product.metafields && metafieldsToObject(product.metafields, getLocalizedMetafields(productMetafields, locale))

  const shopTheLookRefs = metafields?.shop_the_look?.replace(/\s/g, "")?.split(",") ?? []

  const shopTheLookItems = await serializeShopTheLook({ refs: shopTheLookRefs, locale })

  const serializedProduct = serializeProductHeaderSP(product, locale, shopTheLookItems)
  const trackingData = unlikelyProductToTrackingData({ product, locale })

  return {
    serializedProduct,
    shopTheLookItems,
    productByHandle: product,
    trackingData,
  }
}

export type TProductHeader = InferReturn<typeof serializeProductHeader>
