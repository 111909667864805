import { useMemo } from "react"
import clsx from "clsx"
import { useElementSize } from "usehooks-ts"

import { useGetCustomer } from "@unlikelystudio/react-ecommerce-hooks"

import { LangSwitcher } from "~/components/ui/LangSwitcher"
import LoginPanel from "~/components/ui/Panels/Login"
import SquareCta from "~/components/ui/SquareCta"
import Icon from "~/components/abstracts/Icon"
import type { TNavigation } from "~/components/globals/Navigation/_data/serializer"
import { INPDodger } from "~/components/shared/INPDodger/index.client"
import { usePanel } from "~/managers/PanelManager"

import { sprinkles } from "~/styles/sprinkles.css"
import { grid } from "~/styles/variants"

import * as css from "./styles.css"

type MobilePanelFooterProps = {
  navigation: NonNullable<TNavigation>
}

export function MobilePanelFooter({ navigation }: MobilePanelFooterProps) {
  const { data: customer } = useGetCustomer()
  const { add } = usePanel()
  const [ref, { height }] = useElementSize<HTMLElement>()

  const cssVariables = useMemo(() => {
    return `body {
        --mobile-panel-footer: ${height}px;

      }`
  }, [height])

  return (
    <>
      <style>{cssVariables}</style>
      <div className={grid({ type: "base" })} ref={ref}>
        <div className={clsx(css.MobilePanelFooter)}>
          <div className={sprinkles({ flex: "1" })}>
            <LangSwitcher className={css.langSwitcher} />
          </div>

          <div className={sprinkles({ flex: "1", justifyContent: "flex-end", display: "flex" })}>
            <INPDodger>
              {({ cn, onTransition }) => (
                <SquareCta
                  theme="borderBlack"
                  size="small"
                  className={clsx(cn, sprinkles({ display: "inline-flex", alignItems: "center", pX: 10 }))}
                  {...(customer
                    ? { ...navigation.accountLink }
                    : { onClick: () => onTransition(() => add(<LoginPanel />)) })}
                >
                  <span>{navigation.accountLink?.children}</span>
                  <Icon width={12} className={css.iconCta} name="Account" direction="right" theme="currentColor" />
                </SquareCta>
              )}
            </INPDodger>
          </div>
        </div>
      </div>
    </>
  )
}
