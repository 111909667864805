"use client"

import clsx from "clsx"

import type { UnlikelyProduct } from "@unlikelystudio/commerce-connector"
import { ProductList } from "@unlikelystudio/react-ecommerce-hooks"

import { Image, type ImageProps } from "~/components/ui/Image"
import { ProductCardRevamped, type ProductCardRevampedProps } from "~/components/ui/ProductCardRevamped/index.client"
import type { SliceWithProps } from "~/components/slices/SliceManager"

import { sprinkles } from "~/styles/sprinkles.css"
import { breakpoints } from "~/styles/variables/breakpoints"

import * as css from "./styles.css"

const SIZES = {
  SLIDER: [{ breakpoint: breakpoints.md, ratio: 1 / 3 }, { ratio: 1 }],
  IMAGE: [{ ratio: 1 / 3 }],
}

export type ClientGalleryProps = {
  product: UnlikelyProduct
  productSiblings: ProductCardRevampedProps["siblings"]
  image: ImageProps
}

export function ClientGallery({
  className,
  product,
  productSiblings,
  image,
  isFirstSlice,
}: SliceWithProps<ClientGalleryProps>) {
  return (
    <div data-comp="Slices/GalleryElement" className={clsx(className, css.Gallery)}>
      <div className={clsx(css.productContainer)}>
        <ProductList listId="gallery-products">
          <ProductCardRevamped
            className={sprinkles({ height: "100%" })}
            product={product}
            siblings={productSiblings}
            withSiblings={productSiblings.length > 0}
            priority={isFirstSlice}
          />
        </ProductList>
      </div>
      <div className={clsx(css.imageContainer)}>
        <Image
          imageClassName={clsx(css.image)}
          {...image}
          sizesFromBreakpoints={SIZES.IMAGE}
          asPlaceholder
          priority={isFirstSlice}
        />
      </div>
    </div>
  )
}
