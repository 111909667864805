import { notFound } from "next/navigation"
import { captureException } from "@sentry/nextjs"
import type { ISbStoryParams } from "storyblok-js-client"

import { type Nullish, type ParseUrlParams } from "~/@types/generics"
import { DEFAULT_LOCALE } from "~/lib/i18n/constants"
import { getDimension, getLang } from "~/lib/i18n/utils/get-i18n"
import Storyblok from "~/lib/storyblok/client"
import type { SbSlug } from "~/lib/storyblok/constants"
import { getIsPreview } from "~/lib/storyblok/utils/get-is-preview"
import { getReleaseId } from "~/lib/storyblok/utils/get-release-id"
import { getStoryPath } from "~/lib/storyblok/utils/get-story-path"
import isDev from "~/utils/is-dev"
import { logger } from "~/utils/logger"

type StoryOptions = Omit<ISbStoryParams, "language"> & { locale: Nullish<string>; withDraftMode?: boolean }

export async function fetchSbStory<U extends SbSlug, Params extends ParseUrlParams<U>>(
  path: U,
  { locale, withDraftMode = true, ...options }: StoryOptions,
  params: Params
) {
  const processedPath = getStoryPath(path, params)

  try {
    return await getStory(processedPath, locale, withDraftMode, options)
  } catch (error) {
    logger.info(`[Info] SbStory : failed to fetch story for ${locale}:${processedPath}`, error)

    // fetch story with default locale
    try {
      return await getStory(processedPath, DEFAULT_LOCALE, withDraftMode, options)
    } catch (error) {
      const isGlobal = processedPath.startsWith("globals/")
      if (isGlobal) {
        captureException(`Failed to fetch story global data: [${path} - ${locale}] `)
        throw new Error("Failed to fetch story global data")
      }

      logger.warn(`[Error] SbStory : failed to fetch default story for ${DEFAULT_LOCALE}:${processedPath}`, error)
      return notFound()
    }
  }
}

async function getStory(
  path: string,
  locale: Nullish<string>,
  withDraftMode: boolean = true,
  options: Omit<ISbStoryParams, "language">
) {
  const dimension = getDimension(locale)
  const pathWithDimension = `${dimension}/${path}`

  const isPreviewMode = withDraftMode ? await getIsPreview() : false

  if (isPreviewMode) {
    options["version"] = "draft"

    const releaseId = await getReleaseId()
    if (releaseId) {
      options["from_release"] = releaseId
    }
  }

  return await Storyblok.getStory(pathWithDimension, {
    language: getLang(locale),
    resolve_links: "story",
    version: "published",
    cv: isDev ? Date.now() : undefined,
    ...options,
  })
}

export async function fetchSbStoryByID({
  id,
  locale,
  options,
}: {
  id: number
  locale: Nullish<string>
  options?: Omit<ISbStoryParams, "language">
}) {
  if (!id) return null

  const path = `cdn/stories/${id}`

  return await Storyblok.get(path, {
    language: getLang(locale),
    resolve_links: "url",
    version: "published",
    cv: isDev ? Date.now() : undefined,
    ...options,
  })
}
