"use client"

import { useEffect, useRef } from "react"
import { useRouter } from "next/navigation"
import { clsx } from "clsx"
import { useForm, type FieldValues } from "react-hook-form"

import type { PropsWithClassName } from "~/@types/generics"
import { useSearchedTerms } from "~/hooks/useSearchedTerms"
import { useSearchQueryParam } from "~/hooks/useSearchQueryParam"
import Input from "~/components/ui/Input"
import Icon from "~/components/abstracts/Icon"
import LayoutForm from "~/components/abstracts/LayoutForm"
import { useTracking } from "~/providers/GTMTrackingProvider/hooks/use-tracking"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { useInternalRedirects } from "~/providers/InternalRedirectsProvider/index.client"

import * as css from "./styles.css"

type RedirectToSearchPageParams = {
  searchPageURL?: string
  input: FieldValues
  push: ReturnType<typeof useRouter>["push"]
}

type SearchFormProps = PropsWithClassName

const QUERY_PARAM = "?q="

export function redirectToSearchPage({ searchPageURL, input, push }: RedirectToSearchPageParams) {
  const { query } = input ?? {}

  if (!query || !searchPageURL) return
  const path = `${searchPageURL}${QUERY_PARAM}${query}`
  push(path)
}

function SearchForm({ className }: SearchFormProps) {
  const t = useTranslate()
  const { searchQueryParam: queryString } = useSearchQueryParam()

  const { addTerm } = useSearchedTerms()

  const [redirects] = useInternalRedirects()
  const { search } = redirects ?? {}

  const { push } = useRouter()

  const form = useForm({
    defaultValues: { query: queryString },
  })
  const { sendEvent } = useTracking()

  function handleSubmit(input: FieldValues) {
    addTerm(input?.query)
    sendEvent("search", { search_term: input?.query })
    redirectToSearchPage({ searchPageURL: search?.href, input, push })
  }

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    inputRef.current?.focus?.()
  }, [inputRef.current])

  return (
    <div className={clsx(className, css.SearchForm)}>
      <LayoutForm form={form} onSubmit={form.handleSubmit(handleSubmit)}>
        <Input
          placeholder={t("search_input_placeholder")}
          inputWrapperClassName={css.inputWrapper}
          inputClassName={css.input}
          forwardRef={inputRef}
          type="text"
          name="query"
          autoFocus
        >
          <Icon className={css.icon} direction="right" name="MagnifyingGlass" theme="black" />
        </Input>
      </LayoutForm>
    </div>
  )
}

export { SearchForm }
