import dayjs from "dayjs"

export const REFETCH_MINUTES_INTERVAL = 2

/**
 * This function handles product query refetching every X minutes
 * both parameters are returned by the useGetProduct hook
 */
export function refetchOnInterval(dataUpdatedAt: number, refetch: () => void) {
  const lastUpdatedTimestamp = dayjs(dataUpdatedAt).unix()

  if (getTimestampOfNowMinusInterval() > lastUpdatedTimestamp) {
    refetch()
  }
}

export function getTimestampOfNowMinusInterval() {
  return dayjs().subtract(REFETCH_MINUTES_INTERVAL, "minutes").unix()
}
