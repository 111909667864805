"use client"

import clsx from "clsx"
import { useInView } from "react-intersection-observer"

import { useGetProduct } from "@unlikelystudio/react-ecommerce-hooks"

import { Image, type ImageProps } from "~/components/ui/Image"
import { type TImage } from "~/components/ui/Image/_data/serializer"
import Video, { type TVideo } from "~/components/ui/Video"
import type { AbstractLinkProps } from "~/components/abstracts/Link"
import { EmblaSlider } from "~/components/shared/EmblaSlider/index.client"
import { useGridLayoutContext } from "~/providers/GridLayoutProvider/index.client"
import isTouchScreen from "~/utils/is-touch-screen"

import { breakpoints } from "~/styles/variables/breakpoints"

import * as css from "./styles.css"

export const IMAGES_SIZES = {
  "mobile-1-desktop-3": [{ breakpoint: breakpoints.md, ratio: 1 / 3 }, { ratio: 1 }],
  "mobile-2-desktop-4": [{ breakpoint: breakpoints.md, ratio: 1 / 3 }, { ratio: 1 / 2 }],
}

export type CardMediaProps =
  | {
      type: "video"
      data: TVideo
    }
  | {
      type: "image"
      data: TImage
    }

function GalleryItem({
  imageProps,
  media,
}: {
  media: CardMediaProps
  imageProps: Pick<ImageProps, "sizesFromBreakpoints" | "priority">
}) {
  const commonMediaProps = {
    fill: true,
    className: css.image,
  }

  return (
    <div className={css.mediaItem}>
      {media?.type === "video" ? (
        <Video {...commonMediaProps} {...media?.data} />
      ) : (
        <Image asPlaceholder {...imageProps} {...commonMediaProps} {...media?.data} />
      )}
    </div>
  )
}

export type ScrollSnapGalleryProps = Pick<AbstractLinkProps, "href"> &
  Pick<ImageProps, "priority"> & { isOutOfStock?: boolean; medias: CardMediaProps[]; hasDrag?: boolean }

export function ScrollSnapGallery({ medias, href, priority, isOutOfStock, hasDrag = true }: ScrollSnapGalleryProps) {
  const { layout } = useGridLayoutContext()
  const { ref: inViewRef, inView } = useInView({ threshold: 0, triggerOnce: true, initialInView: priority })
  const { data: product } = useGetProduct()

  return (
    <div className={clsx(css.slider, { [css.isOutOfStock]: isOutOfStock })} ref={inViewRef}>
      <EmblaSlider
        className={css.fullHeightItem}
        containerClassName={css.fullHeightItem}
        sliderClassName={css.fullHeightItem}
        controlsClassName={clsx(css.controls)}
        controlButtonClassName={clsx(css.control)}
        displayControlsOnHover={!isTouchScreen}
        controlsStyle="centered"
        buttonStyle="cover"
        buttonType="chevron"
        hideOverflow
        options={{
          align: "start",
          active: medias?.length > 1,
          watchDrag: hasDrag,
        }}
        {...(href && product?.title ? { link: { href, "aria-label": product?.title } } : {})}
      >
        {medias?.map((media, index) => {
          if (!inView && index !== 0) {
            return null
          }

          return (
            <GalleryItem
              key={`slider-image-${media}-${index}`}
              media={media}
              imageProps={{
                priority: priority && index === 0,
                sizesFromBreakpoints: IMAGES_SIZES[layout],
              }}
            />
          )
        })}
      </EmblaSlider>
    </div>
  )
}
