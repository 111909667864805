import clsx from "clsx"

import useAuthentication from "~/hooks/account/useAuthentication"
import { FillImage } from "~/components/ui/FillImage"
import InlineCta from "~/components/ui/InlineCta"
import { Link } from "~/components/ui/Link"
import LoginPanel from "~/components/ui/Panels/Login"
import RichText from "~/components/ui/RichText"
import SquareCta, { type SquareCtaProps } from "~/components/ui/SquareCta"
import type { ProductIconicProps, TProductIconic } from "~/components/slices/ProductIconic"
import { usePanel } from "~/managers/PanelManager"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "../../styles.css"

const squaredCtaType = {
  white: "backgroundWhite",
  black: "backgroundBlack",
} satisfies Record<NonNullable<TProductIconic["theme"]>, SquareCtaProps["theme"]>

const IMAGE_SIZES = [{ ratio: 1 }]

export function ProductIconicItem({
  className,
  item,
  isFirstSlice,
  textSize,
  font,
}: Pick<ProductIconicProps, "className" | "textSize" | "isFirstSlice" | "font" | "withLogin"> & {
  item: TProductIconic
}) {
  const { data: customer } = useAuthentication()
  const shouldOpenLoginPanel = Boolean(item?.withLogin && !customer)
  const { add } = usePanel()

  const returnTo = item?.link?.href ? item.link.href : null
  const content = (
    <div className={clsx(css.ProductIconic({ theme: item.theme }))}>
      {item.image && (
        <FillImage
          className={clsx(css.image, item.mobileImage && sprinkles({ display: { mobile: "none", desktop: "block" } }))}
          sizesFromBreakpoints={IMAGE_SIZES}
          {...item.image}
        />
      )}
      {item.mobileImage && (
        <FillImage
          className={clsx(css.image, sprinkles({ display: { mobile: "block", desktop: "none" } }))}
          sizesFromBreakpoints={IMAGE_SIZES}
          priority={isFirstSlice}
          {...item.mobileImage}
        />
      )}
      <div className={clsx(css.content)}>
        <div
          className={clsx(
            sprinkles({ width: "100%" }),
            css.textContent({ verticalAlign: item.verticalAlign, horizontalAlign: item.horizontalAlign })
          )}
        >
          <RichText
            className={clsx(css.title, css.titleRecipe({ textSize, font }))}
            render={item.title}
            disableStyles
          />
          <RichText
            disableStyles
            className={clsx(
              css.paragraph,
              sprinkles({ marginBottom: 20 }),
              css.paragraphRecipe({ horizontalAlign: item.horizontalAlign })
            )}
            render={item.paragraph}
          />

          {item.ctaType === "inline" && (
            <InlineCta
              className={clsx(css.cta)}
              {...item.link}
              href={undefined}
              withDefaultLine
              color={item.theme}
              size="medium"
            />
          )}
          {item.ctaType === "squared" && (
            <SquareCta
              className={clsx(css.cta)}
              theme={squaredCtaType[item.theme ?? "black"]}
              {...item.link}
              href={undefined}
            />
          )}
        </div>
      </div>
    </div>
  )

  return shouldOpenLoginPanel ? (
    <div
      className={clsx(
        className,
        { [css.isFirstSlice]: isFirstSlice },
        sprinkles({ display: "block", width: "100%", position: "relative", cursor: "pointer" })
      )}
      data-comp="Slices/ProductIcon"
      onClick={() => add(<LoginPanel {...(returnTo ? { returnTo } : null)} />)}
    >
      {content}
    </div>
  ) : (
    <Link
      data-comp="Slices/ProductIconic"
      className={clsx(
        className,
        { [css.isFirstSlice]: isFirstSlice },
        sprinkles({ display: "block", width: "100%", position: "relative" })
      )}
      {...item.link}
      aria-label={item.link?.children}
    >
      {content}
    </Link>
  )
}
