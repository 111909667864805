import type { UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import { productMetafields } from "~/lib/shopify/constants"
import { getLocalizedMetafields } from "~/lib/shopify/utils/get-localized-metafields"
import type { TLinkedProductsCard } from "~/components/ui/LinkedProductsCard/_data/serializer"
import type { ShopTheLook } from "~/components/ui/Panels/ShopTheLook/_data/serializer"
import type { CartLineItemPayload } from "~/providers/GTMTrackingProvider/constants"
import { metafieldsToObject } from "~/utils/metafields-to-object"

export function getProductTrackingDataWithCollections(
  trackingData: CartLineItemPayload,
  product: UnlikelyProduct | TLinkedProductsCard | ShopTheLook[number],
  locale: Nullish<string>
) {
  if (!product) return trackingData

  // retrieve filter attributes from shopify metafields
  const apparel = metafieldsToObject(
    product?.metafields ?? {},
    getLocalizedMetafields(productMetafields, locale)
  )?.apparel

  return {
    ...trackingData,
    ...(apparel ? { item_category: apparel } : {}),
  }
}
