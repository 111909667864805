"use client"

import type { RecipeVariants } from "@vanilla-extract/recipes"

import type { Nullish } from "~/@types/generics"
import type { SbRichtext } from "~/lib/storyblok/schemas/default/richtext"
import type { TImage } from "~/components/ui/Image/_data/serializer"
import type { TLinkLabel } from "~/components/ui/Link/_data/serializer"
import { ProductIconicItem } from "~/components/slices/ProductIconic/_components/ProductIconicItem"
import ProductIconicSliderClient from "~/components/slices/ProductIconic/_components/ProductIconicSliderClient/index.client"
import type { SliceWithProps } from "~/components/slices/SliceManager"

import type * as css from "./styles.css"

export type TProductIconic = {
  title?: SbRichtext
  paragraph?: SbRichtext
  image: Nullish<TImage>
  mobileImage?: Nullish<TImage>
  link: Nullish<TLinkLabel>
  ctaType?: "inline" | "squared"
  withLogin?: boolean
} & RecipeVariants<typeof css.ProductIconic> &
  RecipeVariants<typeof css.textContent> &
  RecipeVariants<typeof css.titleRecipe>
export type ProductIconicProps = SliceWithProps<TProductIconic>

function ProductIconic({
  className,
  title,
  paragraph,
  image,
  mobileImage,
  link,
  theme,
  verticalAlign,
  isFirstSlice = false,
  withLogin = false,
  horizontalAlign,
  ctaType = "squared",
  textSize = "medium",
  items,
  font = "primary",
}: ProductIconicProps & { items?: TProductIconic[] }) {
  return items && items?.length >= 1 ? (
    <ProductIconicSliderClient isInfinite={items?.length > 1}>
      {items?.map((item, key) => (
        <ProductIconicItem
          key={`product_iconic_item_${key}`}
          className={className}
          item={item}
          isFirstSlice={isFirstSlice}
        />
      ))}
    </ProductIconicSliderClient>
  ) : (
    <ProductIconicItem
      className={className}
      item={{
        title,
        paragraph,
        image,
        mobileImage,
        link,
        theme,
        verticalAlign,
        horizontalAlign,
        ctaType,
        withLogin,
      }}
      isFirstSlice={isFirstSlice}
      textSize={textSize}
      font={font}
    />
  )
}

export default ProductIconic
