"use client"

import { useCallback } from "react"
import clsx from "clsx"

import type { UnlikelyCustomAttributes, UnlikelyVariant } from "@unlikelystudio/commerce-connector"
import { useGetCustomer, useRemoveCartLines, useUpdateCartLines } from "@unlikelystudio/react-ecommerce-hooks"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import { getProductCustomAttributesValue, PRODUCT_CUSTOM_ATTRIBUTES } from "~/lib/shopify/constants"
import { useAlgoliaCartTrackingAttributes } from "~/hooks/useAlgoliaCartTrackingAttributes"
import useLocale from "~/hooks/useLocale"
import type { UnlikelyCartLine } from "~/components/ui/CartPanel"
import { useSerializeOptions } from "~/components/ui/CartPanel/hooks/serialize-options-from-variants"
import { persistAttributesOnChange } from "~/components/ui/CartPanel/LineItem/utils/persist-attributes"
import SizeSelect from "~/components/ui/CartPanel/SizeSelect"
import { Image } from "~/components/ui/Image"
import serializeImage from "~/components/ui/Image/_data/serializer"
import InlineCta from "~/components/ui/InlineCta"
import Price from "~/components/ui/Price"
import { serializeSfPrice } from "~/components/ui/Price/_data/serializer"
import { serializeVariant } from "~/components/ui/ProductHeader/_data/serialize-variant"
import type { CartLineItemPayload } from "~/providers/GTMTrackingProvider/constants"
import { variantToTrackingData } from "~/providers/GTMTrackingProvider/utils/variant-to-tracking-data"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { processVpPrice } from "~/utils/vp-prices/process-vp-prices"

import * as css from "./styles.css"

type LineItemProps = PropsWithClassName<{ line: UnlikelyCartLine; onClick: Function; sendCartEvent: Function }>

function LineItem({ className, onClick, sendCartEvent, line }: LineItemProps) {
  const t = useTranslate()
  const { data: customer } = useGetCustomer()
  const locale = useLocale()

  const { merchandise, quantity, id, attributes } = line ?? {}
  const { title, variants, isGiftCard = false } = merchandise?.product ?? {}
  const selectsOptions = useSerializeOptions(variants as UnlikelyVariant[], isGiftCard)
  const { updateAndCheckAlgoliaAttributes } = useAlgoliaCartTrackingAttributes()
  const { mutate: update } = useUpdateCartLines()
  const { mutate: remove } = useRemoveCartLines({
    onSuccess(data, variables, context) {
      const deletedLineItemId = variables[0]
      const deletedLineItem = context?.previousCart?.cart?.lines.find((line) => line.id === deletedLineItemId)

      if (deletedLineItem) {
        if (deletedLineItem?.merchandise?.id) {
          updateAndCheckAlgoliaAttributes({
            variantId: deletedLineItem?.merchandise?.id,
            trackAsAlgoliaConversion: false,
            direction: "remove",
          })
        }
        sendCartEvent("remove_from_cart", data.cart, [deletedLineItem])
      }
    },
  })

  const onVariantUpdateMiddleware = useCallback(
    ({ attributes, variant }: { attributes: UnlikelyCustomAttributes; variant: Nullish<UnlikelyVariant> }) => {
      const processedTrackingData = variant ? variantToTrackingData(serializeVariant(variant, locale)) : {}
      const previousTrackingData = getProductCustomAttributesValue(attributes, "TRACKING_DATA")?.value ?? ""
      const parsedTrackingData = JSON.parse(previousTrackingData) as CartLineItemPayload

      // Retrieve existing line attributes apart from those that will be updated
      const existingAttributes = persistAttributesOnChange(attributes)

      return {
        attributes: [
          ...existingAttributes,
          {
            key: PRODUCT_CUSTOM_ATTRIBUTES.TRACKING_DATA,
            value: JSON.stringify({
              ...parsedTrackingData,
              ...processedTrackingData,
            }),
          },
          variant?.image && variant.image?.url
            ? { key: PRODUCT_CUSTOM_ATTRIBUTES.THUMBNAIL, value: JSON.stringify({ url: variant.image.url }) }
            : null,
        ].filter(Boolean),
      }
    },
    [JSON.stringify(attributes)]
  )
  if (!variants) return null

  const selectedLineVariant = variants?.find(
    (variant) => variant?.id === line?.merchandise?.id
  ) as Nullish<UnlikelyVariant>

  if (!merchandise) return null

  const image = serializeImage(merchandise?.image, locale)

  const serializedLineItemVariant = serializeVariant(merchandise, locale)
  const { processedPrice, processedCompareAtPrice } = processVpPrice({
    price: serializedLineItemVariant?.price,
    compareAtPrice: serializedLineItemVariant?.compareAtPrice,
    vpPrice: serializedLineItemVariant?.vpPrice,
    customer,
  })

  const serializedPrice = serializeSfPrice(locale, processedPrice, processedCompareAtPrice)

  const quantityOptions = Array.from({ length: 10 }, (_, index) => ({
    value: index + 1,
    label: String(index + 1),
    disabled: false,
  }))

  return (
    <div className={clsx(css.LineItem, className)}>
      <button
        className={css.image}
        onClick={() => {
          onClick?.()
        }}
      >
        {image?.src && <Image {...image} width={110} ratio="115_172" asPlaceholder />}
      </button>
      <div className={css.content}>
        <div className={clsx(css.header)}>
          <p className={css.titleItem}>{title}</p>
          {serializedPrice && <Price className={css.price} {...serializedPrice} />}
        </div>
        <div className={clsx(css.options)}>
          {selectsOptions?.map(([key, value]) => (
            <SizeSelect
              key={key}
              defaultValue={selectedLineVariant?.id ?? undefined}
              name={key}
              options={value}
              onChange={(e) => {
                const newVariant = e?.target?.value

                const cartLine = onVariantUpdateMiddleware({
                  attributes,
                  variant: variants?.find((v) => v?.id === newVariant),
                })

                update([
                  {
                    id,
                    merchandiseId: newVariant,
                    quantity,
                    ...cartLine,
                  },
                ])
              }}
            />
          ))}
          <SizeSelect
            className={clsx(css.quantitySelect)}
            defaultValue={quantity}
            name={t("cart_quantity")}
            onChange={(e) => {
              const newQuantity = Number.parseInt(e?.target?.value)

              if (!isNaN(newQuantity)) {
                const cartLine = onQuantityUpdateMiddleware({
                  quantity,
                  attributes,
                })
                update([
                  {
                    id,
                    quantity: newQuantity,
                    ...cartLine,
                  },
                ])
              }
            }}
            options={quantityOptions}
          />
          <InlineCta className={clsx(css.remove)} withDefaultLine onClick={() => remove([id])}>
            {t("cart_delete")}
          </InlineCta>
        </div>
      </div>
    </div>
  )
}

export default LineItem

function onQuantityUpdateMiddleware({
  quantity,
  attributes,
}: {
  quantity: number
  attributes: UnlikelyCustomAttributes
}) {
  const previousTrackingData = getProductCustomAttributesValue(attributes, "TRACKING_DATA")?.value ?? ""
  const parsedTrackingData = JSON.parse(previousTrackingData) as CartLineItemPayload

  // Retrieve existing line attributes apart from those that will be updated
  const existingAttributes = persistAttributesOnChange(attributes)

  return {
    attributes: [
      ...existingAttributes,
      {
        key: PRODUCT_CUSTOM_ATTRIBUTES.TRACKING_DATA,
        value: JSON.stringify({
          ...parsedTrackingData,
          quantity,
        }),
      },
    ],
  }
}
