"use client"

import { useEffect } from "react"
import { usePathname } from "next/navigation"

import { PageViewAnalytics, type PageTemplate } from "@unlikelystudio/react-ecommerce-hooks"

import type { PageName } from "~/providers/GTMTrackingProvider/constants"
import { useTracking } from "~/providers/GTMTrackingProvider/hooks/use-tracking"

type ClientPageViewProps = {
  pageName: PageName
  templateName: PageTemplate
}

export function ClientPageView({ pageName, templateName }: ClientPageViewProps) {
  const { sendEvent } = useTracking()
  const pathname = usePathname()

  useEffect(() => {
    sendEvent("page_view", {
      page_type: pageName,
      virtual_page_location: document.URL,
      virtual_page_title: document.title,
      virtual_page_referrer: document.referrer,
    })
  }, [])

  return <PageViewAnalytics pathname={pathname} template={templateName} />
}
