import type { UnlikelyCustomAttributes } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"

type ProductCustomAttributes = typeof PRODUCT_CUSTOM_ATTRIBUTES

export const PRODUCT_CUSTOM_ATTRIBUTES = {
  TRACKING_DATA: "_trackingData",
  THUMBNAIL: "_thumbnail",
} as const

export function getProductCustomAttributesValue(
  customAttributes: Nullish<UnlikelyCustomAttributes>,
  key: keyof ProductCustomAttributes
) {
  return customAttributes?.find((attr) => attr.key === PRODUCT_CUSTOM_ATTRIBUTES[key]) ?? null
}

type CheckoutCustomAttributes = typeof CHECKOUT_CUSTOM_ATTRIBUTES

export const CHECKOUT_CUSTOM_ATTRIBUTES = {
  CHECKOUT_DOMAIN_REFERRAL: "_checkout-domain-referral",
  CHECKOUT_ENV: "_checkout-env",
  CHECKOUT_IS_VP: "_checkout-is-vp",
  CHECKOUT_ALGOLIA_CONFIG: "_checkout-algolia-config",
  CHECKOUT_ALGOLIA_SEARCH_IDS: "_checkout-algolia-search-ids",
} as const

export function getCheckoutCustomAttributesValue(
  customAttributes: Nullish<UnlikelyCustomAttributes>,
  key: keyof CheckoutCustomAttributes
) {
  return customAttributes?.find((attr) => attr.key === CHECKOUT_CUSTOM_ATTRIBUTES[key]) ?? null
}
