import type { GID } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import { algoliaPublicClient } from "~/lib/algolia/public-client"
import type { AlgoliaProductHit } from "~/lib/algolia/types"
import { getIndexName } from "~/lib/algolia/utils/get-index-name"
import { DEFAULT_LOCALE } from "~/lib/i18n/constants"
import type { I18nLocale } from "~/lib/i18n/types"
import { getReferenceIdFromGID, isGID } from "~/lib/shopify/utils/id"

export async function fetchAlgoliaRecommendations({
  ids,
  locale = DEFAULT_LOCALE,
  maxRecommendations = 8,
}: {
  ids: GID[]
  locale: Nullish<string>
  maxRecommendations?: number
}) {
  const objectIDs = ids?.filter(isGID)?.map(getReferenceIdFromGID)?.filter(Boolean) ?? []

  if (!objectIDs?.length) return []

  const indexName = getIndexName({ locale: locale as I18nLocale })

  const input = objectIDs?.map((objectID) => ({
    indexName,
    objectID: objectID.toString(),
    model: "related-products",
    maxRecommendations: Math.ceil(maxRecommendations / objectIDs.length),
  })) satisfies Parameters<typeof algoliaPublicClient.getRecommendations<AlgoliaProductHit>>[0]

  const response = await algoliaPublicClient.getRecommendations<AlgoliaProductHit>(input)?.catch(() => {
    return null
  })

  return response?.results?.flatMap((response) => response?.hits?.filter(Boolean)) ?? []
}
