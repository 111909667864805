"use client"

import { createContext, useContext, useState, type Dispatch, type PropsWithChildren, type SetStateAction } from "react"

export type GridLayout = "mobile-1-desktop-3" | "mobile-2-desktop-4"

const DEFAULT_LAYOUT: GridLayout = "mobile-1-desktop-3"

type GridLayoutContextProps = {
  layout: GridLayout
  setLayout: Dispatch<SetStateAction<GridLayout>>
}

export const GridLayoutContext = createContext<GridLayoutContextProps>({
  layout: DEFAULT_LAYOUT,
  setLayout: () => {},
})

export function GridLayoutProvider({ children }: PropsWithChildren) {
  const [layout, setLayout] = useState<GridLayout>(DEFAULT_LAYOUT)

  return <GridLayoutContext.Provider value={{ layout, setLayout }}>{children}</GridLayoutContext.Provider>
}

export function useGridLayoutContext() {
  return useContext(GridLayoutContext)
}
