import { useEffect, useState } from "react"

import useCurrency from "~/hooks/useCurrency"
import { useGetCart } from "~/hooks/useGetCart"
import type { PageName, PagePayload, TrackingGlobals } from "~/providers/GTMTrackingProvider/constants"

export function useGlobalTrackingProps(pageTitle?: PageName) {
  const { cartPayload } = useGetCart()
  const currency = useCurrency()
  const [props, setProps] = useState({})

  useEffect(() => {
    setProps({
      content_type: "Product",
      currency: currency.toUpperCase(),
      page_type: pageTitle,
      value: cartPayload?.cost.totalAmount.amount ?? 0,
      virtual_page_referrer: document.referrer,
      virtual_page_location: document.URL,
      virtual_page_title: document.title,
    })
  }, [])

  return props as PagePayload & TrackingGlobals
}
