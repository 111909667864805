import type { UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import type { CardProps } from "~/components/ui/CartPanel/CompleteTheLookSlider/Card"
import serializeLink from "~/components/ui/Link/_data/serializer"
import { unlikelyProductToTrackingData } from "~/providers/GTMTrackingProvider/utils/unlikely-product-to-tracking-data"

export function serializeCardCompleteTheLookCard(data: Nullish<UnlikelyProduct>, locale: string): CardProps | null {
  if (!data) return null

  const product = data

  return {
    id: data.id as string,
    handle: data.handle,
    collectionsHandles: product.collections.map((collection) => collection.handle).filter(Boolean),
    trackingData: unlikelyProductToTrackingData({ product, locale }),
    product,
    link: serializeLink(data, locale),
  }
}
