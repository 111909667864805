import { clsx } from "clsx"

import type { UnlikelyVariant } from "@unlikelystudio/commerce-connector"
import { useGetCustomer, useGetProduct } from "@unlikelystudio/react-ecommerce-hooks"

import type { InferReturn, PropsWithClassName } from "~/@types/generics"
import { formatCheckoutItems } from "~/lib/shopify/utils/format-checkout-items"
import { getSelectedOption } from "~/lib/shopify/utils/get-selected-option"
import { useAddToCart } from "~/hooks/useAddToCart"
import { useAlgoliaCartTrackingAttributes } from "~/hooks/useAlgoliaCartTrackingAttributes"
import { useGetVariantsInventory } from "~/hooks/useGetVariantInventory"
import useLocale from "~/hooks/useLocale"
import { useProcessVariantVPPrice } from "~/hooks/useProcessVariantVPPrice"
import type { ProductCardRevampedProps } from "~/components/ui/ProductCardRevamped/index.client"
import type { TVariant } from "~/components/ui/ProductHeader/_data/types"
import Spinner from "~/components/ui/Spinner"
import { unlikelyProductToTrackingData } from "~/providers/GTMTrackingProvider/utils/unlikely-product-to-tracking-data"
import { variantToTrackingData } from "~/providers/GTMTrackingProvider/utils/variant-to-tracking-data"
import { processVpPrice } from "~/utils/vp-prices/process-vp-prices"

import * as css from "./styles.css"

type TrackAlgoliaConversionProps = Pick<ProductCardRevampedProps, "trackAsAlgoliaConversion">

function QuickbuyVariantButton({
  variant,
  trackingData,
  inventories,
  trackAsAlgoliaConversion = false,
}: {
  variant: UnlikelyVariant
  trackingData: InferReturn<typeof unlikelyProductToTrackingData>
  inventories: InferReturn<typeof useGetVariantsInventory>["data"]
  trackAsAlgoliaConversion: TrackAlgoliaConversionProps["trackAsAlgoliaConversion"]
}) {
  const { data: customer } = useGetCustomer()

  const updatedTrackingData = {
    ...trackingData,
    ...(variantToTrackingData(variant as TVariant) ?? {}),
  }

  const { price, compareAtPrice } = variant ?? {}
  const vpPrice = useProcessVariantVPPrice({ variant })

  const { processedPrice } = processVpPrice({
    price,
    compareAtPrice,
    vpPrice,
    customer,
  })

  const { updateAndCheckAlgoliaAttributes } = useAlgoliaCartTrackingAttributes()
  const { mutate, isLoading: isAddingToCart } = useAddToCart(
    {
      id: variant?.id,
      price: processedPrice,
    },
    (_, variables) => {
      const addedLineItemId = variables?.[0]?.merchandiseId

      if (addedLineItemId) {
        updateAndCheckAlgoliaAttributes({ variantId: addedLineItemId, trackAsAlgoliaConversion, direction: "add" })
      }
    }
  )

  const checkoutItems = formatCheckoutItems({ variant, trackingData: updatedTrackingData })
  const inventory = inventories?.find((entry) => entry?.sku && entry?.sku === variant?.sku)

  return isAddingToCart ? (
    <Spinner />
  ) : (
    <button
      className={css.item({
        disabled: !Boolean((inventory ? inventory?.quantityAvailable ?? 0 : variant?.quantityAvailable ?? 0) > 0),
      })}
      onClick={() => mutate(checkoutItems)}
    >
      {getSelectedOption(
        { ...variant, ...(inventory && { quantityAvailable: inventory?.quantityAvailable ?? 0 }) },
        "size"
      )}
    </button>
  )
}

export function QuickbuyRevamped({
  className,
  shouldFetchInventories = false,
  trackAsAlgoliaConversion = false,
}: PropsWithClassName<{ shouldFetchInventories?: boolean } & TrackAlgoliaConversionProps>) {
  const locale = useLocale()
  const { data: product } = useGetProduct()

  const {
    data: inventories,
    isLoading: queryIsLoading,
    enabled,
  } = useGetVariantsInventory({ enabled: shouldFetchInventories })

  if (!product?.variants?.length) return null

  const trackingData = unlikelyProductToTrackingData({ product, locale })
  const isLoading = queryIsLoading && enabled && shouldFetchInventories

  return (
    <div className={clsx(className, css.quickbuy)}>
      {isLoading && <Spinner className={clsx(css.spinner)} />}
      {!isLoading &&
        product?.variants?.filter(Boolean)?.map((variant, index) => {
          return (
            <QuickbuyVariantButton
              key={`quickbuy-variant-${variant?.id}-${index}`}
              variant={variant}
              trackingData={trackingData}
              inventories={inventories}
              trackAsAlgoliaConversion={trackAsAlgoliaConversion}
            />
          )
        })}
    </div>
  )
}
