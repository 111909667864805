"use client"

import type { ComponentProps } from "react"
import type { RecipeVariants } from "@vanilla-extract/recipes"
import clsx from "clsx"

import type { UnlikelyProduct } from "@unlikelystudio/commerce-connector"
import { ProductList } from "@unlikelystudio/react-ecommerce-hooks"

import type { PropsWithClassName } from "~/@types/generics"
import InlineCta from "~/components/ui/InlineCta"
import { ProductCardRevamped, type ProductCardRevampedProps } from "~/components/ui/ProductCardRevamped/index.client"
import RichText from "~/components/ui/RichText"
import type { PushProductsProps } from "~/components/slices/PushProducts"

import * as css from "./styles.css"

export type ClientPushProductsProps = {
  products: UnlikelyProduct[]
  productsSiblings: ProductCardRevampedProps["siblings"][]
} & Pick<PushProductsProps, "headline" | "description" | "cta" | "isFirstSlice"> &
  ComponentProps<"div"> &
  RecipeVariants<typeof css.headline>

export function ClientPushProducts({
  className,
  headline,
  description,
  cta,
  products,
  productsSiblings,
  isFirstSlice,
  font = "primary",
  ...rest
}: PropsWithClassName<ClientPushProductsProps>) {
  return (
    <div className={clsx(className, css.PushProducts)} {...rest}>
      <div className={clsx(css.wrapper({ type: "text" }))}>
        <div className={clsx(css.content)}>
          <RichText className={clsx(css.headline({ font }))} render={headline} disableStyles />
          <RichText render={description} />
          <InlineCta {...cta} size="small" weight="medium" withDefaultLine />
        </div>
      </div>
      <ProductList listId="slice-push-products">
        <div className={clsx(css.wrapper({ type: "products" }))}>
          {products.map((product, index) => {
            const currentSiblings = productsSiblings?.[index] ?? []

            return (
              <ProductCardRevamped
                className={clsx(css.productWidth)}
                key={`${product.title}-${index}`}
                product={product}
                priority={isFirstSlice && index === 0}
                siblings={currentSiblings}
                withSiblings={currentSiblings && currentSiblings?.length > 0}
              />
            )
          })}
        </div>
      </ProductList>
    </div>
  )
}
