import { getReferenceIdFromGID } from "~/lib/shopify/utils/id"
import type { TVariant } from "~/components/ui/ProductHeader/_data/types"
import type { CartLineItemPayload } from "~/providers/GTMTrackingProvider/constants"

export function variantToTrackingData(
  variant: TVariant | null
): Pick<CartLineItemPayload, "item_variant" | "price" | "discount" | "item_variant_id"> | null {
  if (!variant) return null
  const compareAtPrice = variant?.compareAtPrice?.amount

  return {
    item_variant: variant?.sku ?? "",
    price: variant.price?.amount ?? 0,
    discount: compareAtPrice ? compareAtPrice - (variant?.price?.amount ?? 0) : 0,
    item_variant_id: String(getReferenceIdFromGID(variant?.id)) ?? null,
  }
}
