import { useEffect, type ComponentProps, type HTMLInputTypeAttribute, type ReactNode } from "react"
import clsx from "clsx"
import { useFormContext } from "react-hook-form"

import {
  Field,
  FieldGender,
  FieldPassword,
  FieldsetCustomer,
  formDictionary,
  TemplatesProvider,
} from "@unlikelystudio/react-ecommerce-hooks"

import InlineCta from "~/components/ui/InlineCta"
import SquareCta from "~/components/ui/SquareCta"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { objectKeys } from "~/utils/object-keys"

import * as css from "./styles.css"

type TemplatesProviderProps = ComponentProps<typeof TemplatesProvider>

export enum FORM_NAMES {
  CREATE_ADDRESS = "create_address_form",
  CONTACT = "contact_form",
  ENABLE_ACCOUNT = "enable_account_form",
  FORGOT_PASSWORD = "forgot_password_form",
  LOGIN = "login_form",
  NEWSLETTER = "newsletter_form",
  REGISTER = "register_form",
  RESET_PASSWORD = "reset_password_form",
  UPDATE_ADDRESS = "update_address_form",
  UPDATE_INFORMATION = "update_information_form",
  GIFT_CARD = "gift_card",
}

export type FormTemplateProps = TemplatesProviderProps & {
  className?: string
  isLoading?: boolean
  withRequiredText?: boolean
  formName: FORM_NAMES
}

type SubmitButtonProps = {
  children: ReactNode
  disabled: boolean
} & Pick<FormTemplateProps, "formName">

function SubmitButton({ children, disabled }: SubmitButtonProps) {
  const t = useTranslate()

  const { formState } = useFormContext() ?? {}

  useEffect(() => {
    if (formState?.errors && Object.keys(formState.errors)?.length > 0) {
      // tracker.emit(TRACKING_EVENTS.SUBMIT_ERROR, {
      //   form_name: formName,
      //   form_fields: Object.keys(formState?.errors)
      //     ?.map((key) => key)
      //     ?.join(","),
      // })
    }
  }, [formState?.isSubmitting, formState?.errors])

  return (
    <SquareCta disabled={disabled} theme="backgroundBlack" aria-label={t("aria_submit")}>
      {children}
    </SquareCta>
  )
}

function getType(type: HTMLInputTypeAttribute | undefined) {
  return objectKeys(css.type).find((key) => key === type)
}

function FormTemplate({
  className,
  dictionary = formDictionary,
  components,
  children,
  isLoading,
  formName,
  withRequiredText,
}: FormTemplateProps) {
  const t = useTranslate()
  return (
    <div className={clsx(className, css.FormTemplate)}>
      <TemplatesProvider
        dictionary={dictionary}
        components={{
          fieldGender(props) {
            return <FieldGender {...props} withOtherGender={false} />
          },
          link(props) {
            return <InlineCta withLine withDefaultLine {...props} color="black" />
          },
          fieldPassword(props) {
            return <FieldPassword {...props} showPasswordInput />
          },
          field(props) {
            const type = getType(props.type)

            return (
              <Field
                {...props}
                inputLabelWrapperClassName={clsx(type ? css.type[type] : null)}
                labelRequiredText={withRequiredText ? " *" : undefined}
                inputProps={{ ...props.inputProps }}
              />
            )
          },
          fieldContainer(props) {
            return <div {...props} className={css.fieldContainer} />
          },
          fieldsetCustomer(props) {
            return (
              <FieldsetCustomer
                {...props}
                fieldsOrder={[
                  "gender",
                  "firstName",
                  "lastName",
                  "email",
                  "birthdate",
                  "phone",
                  "password",
                  "acceptsMarketing",
                ]}
              />
            )
          },
          input: (props) => {
            switch (props?.type) {
              case "submit":
                return (
                  <SubmitButton formName={formName} disabled={Boolean(props?.disabled || isLoading)}>
                    {props?.value ?? t("cta_submit")}
                  </SubmitButton>
                )
              default:
                return null as unknown as JSX.Element
            }
          },
          ...components,
        }}
      >
        {children}
      </TemplatesProvider>
    </div>
  )
}

export { FormTemplate }
