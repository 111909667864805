import type { UnlikelyVariant } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import { getCountry } from "~/lib/i18n/utils/get-i18n"
import { productVariantMetafields, STOCK_LOW } from "~/lib/shopify/constants"
import { getLocalizedMetafields } from "~/lib/shopify/utils/get-localized-metafields"
import { getSelectedOption } from "~/lib/shopify/utils/get-selected-option"
import getVariantAvailabilityFromMarket from "~/lib/shopify/utils/get-variant-availibility-from-market"
import { processCompareAtPrice } from "~/lib/shopify/utils/process-compare-at-price"
import serializePrice from "~/data/price/serializer"
import { serializeVpPrices } from "~/components/ui/ProductHeader/_data/serialize-vp-price"
import type { TVariant } from "~/components/ui/ProductHeader/_data/types"
import { metafieldsToObject } from "~/utils/metafields-to-object"

export function serializeVariant(variant: UnlikelyVariant, locale: Nullish<string>): TVariant {
  const availabilityFromMarket = getVariantAvailabilityFromMarket(variant, locale)
  const variantMetafields =
    variant.metafields &&
    metafieldsToObject(variant.metafields, getLocalizedMetafields(productVariantMetafields, locale))

  const priceCurrencyCode = variant?.price?.currencyCode ?? null
  const currentMarket = getCountry(locale)
  const vpPrice =
    variantMetafields?.priceMarketsSales && priceCurrencyCode
      ? serializeVpPrices(variantMetafields?.priceMarketsSales, currentMarket, priceCurrencyCode)
      : null

  return {
    id: variant?.id ?? null,
    title: variant?.title ?? null,
    availableForSale: getAvailableForSaleVariant(variant, locale),
    quantityAvailable: availabilityFromMarket,
    price: variant?.price ?? null,
    compareAtPrice: processCompareAtPrice(variant) ?? null,
    vpPrice: vpPrice,
    sku: variant?.sku,
    formattedPrice: serializePrice(locale, priceCurrencyCode, variant?.price?.amount),
    isStockLow:
      Boolean(variant?.availableForSale && (availabilityFromMarket ?? 0) <= STOCK_LOW) &&
      (availabilityFromMarket ?? 0) > 0,
    size: getSelectedOption(variant, "size"),
    color: getSelectedOption(variant, "color"),
    image: variant?.image,
  }
}

export function serializeVariants(variants: UnlikelyVariant[], locale: Nullish<string>): TVariant[] {
  return variants
    ?.map((variant) => serializeVariant(variant, locale))
    .sort((a, b) => Number(a?.size?.replace(/[^0-9]/g, "") ?? 0) - Number(b?.size?.replace(/[^0-9]/g, "") ?? 0))
}

export function getAvailableForSaleVariant(variant: UnlikelyVariant, locale: Nullish<string>) {
  const availabilityFromMarket = getVariantAvailabilityFromMarket(variant, locale)

  return (variant?.availableForSale && availabilityFromMarket > 0) ?? false
}
