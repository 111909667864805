"use client"

import { useCallback, useEffect, useState } from "react"
import { type EmblaCarouselType } from "embla-carousel"

import type { Nullish } from "~/@types/generics"

import css from "./styles.module.scss"

export function EmblaSliderStepper({ emblaApi, length }: { emblaApi: Nullish<EmblaCarouselType>; length: number }) {
  const [_activeIndex, setActiveIndex] = useState(0)

  const updateStepper = useCallback((emblaApi: EmblaCarouselType) => {
    const newIndex = emblaApi?.selectedScrollSnap?.()
    setActiveIndex(newIndex)
  }, [])

  function handleClick(index: number) {
    setActiveIndex(index)
    if (emblaApi) emblaApi.scrollTo?.(index)
  }

  useEffect(() => {
    if (emblaApi && length > 1) emblaApi.on?.("select", updateStepper)
  }, [emblaApi, updateStepper, length])

  if (!length) return null

  return (
    // the data-comp attribute always us to apply custom without passing cascading classname prop
    <div data-comp="embla-stepper" className={css.EmblaSliderStepper}>
      {Array.from({ length })?.map((_, index) => {
        return (
          <div
            key={`indicator-${index}`}
            // activeIndex={activeIndex}
            // index={index}
            onClick={() => handleClick(index)}
            // theme={theme}
          />
        )
      })}
    </div>
  )
}
