"use client"

import { type ComponentProps } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { useInView } from "react-intersection-observer"

import type { sfProduct, UnlikelyProduct } from "@unlikelystudio/commerce-connector"
import { ProductCard as UnlikelyProductCard } from "@unlikelystudio/react-ecommerce-hooks"

import type { InferReturn, PropsWithClassName } from "~/@types/generics"
import { productMetafields, productVariantMetafields, selectedOptions } from "~/lib/shopify/constants"
import type { getSfProduct } from "~/lib/shopify/queries/get-sf-product"
import { getDefaultVariant } from "~/lib/shopify/utils/get-default-variants"
import { getLocalizedMetafields } from "~/lib/shopify/utils/get-localized-metafields"
import useLocale from "~/hooks/useLocale"
import useMarket from "~/hooks/useMarket"
import type { RhythmItem } from "~/components/ui/AlgoliaCollectionGrid/AlgoliaListingGrid/_utils/get-first-image-type"
import type { ImageProps } from "~/components/ui/Image"
import {
  PRODUCT_SIBLING_QUERY_KEY,
  ProductCardSiblingsProvider,
  useProductCardSiblings,
} from "~/components/ui/ProductCardRevamped/_providers/ProductCardColorProvider"
import { getTimestampOfNowMinusInterval } from "~/components/ui/ProductCardRevamped/_utils/refetch-on-interval"
import { CardContent } from "~/components/ui/ProductCardRevamped/CardContent/index.client"
import type { ScrollSnapGalleryProps } from "~/components/ui/ProductCardRevamped/CardContent/ScrollSnapGallery/index.client"
import { serializeVariants } from "~/components/ui/ProductHeader/_data/serialize-variant"
import { ErrorBoundary } from "~/components/abstracts/ErrorBoundary"
import { objectValues } from "~/utils/object-values"

export const PRODUCT_ROOT_FIELDS: Partial<Parameters<typeof sfProduct>[1]> = {
  variantsFirst: 100,
  collectionsFirst: 100,
  includeDescriptionHtml: true,
  imagesFirst: 100,
  includeSeo: true,
  includeFeaturedImage: false,
  includeSelectedOptions: true,
  includeTags: true,
  includeQuantityAvailable: true,
  mediaFirst: 100,
  includePrices: true,
}

export type ProductCardRevampedProps = PropsWithClassName<{
  product: UnlikelyProduct
  trackAsAlgoliaConversion?: boolean
  onClick?: () => void
}> & { siblings: Pick<UnlikelyProduct, "id" | "handle" | "metafields">[]; firstImageType?: RhythmItem } & Pick<
    ScrollSnapGalleryProps,
    "hasDrag"
  > &
  Pick<ImageProps, "priority">

function sortVariants(variants: UnlikelyProduct["variants"]) {
  return variants?.sort((a, b) => {
    const aSize = a?.selectedOptions?.find(
      (option) => option?.name && selectedOptions?.size?.includes(option?.name)
    )?.value

    const bSize = b?.selectedOptions?.find(
      (option) => option?.name && selectedOptions?.size?.includes(option?.name)
    )?.value

    return Number(aSize?.replace(/[^0-9]/g, "") ?? 0) - Number(bSize?.replace(/[^0-9]/g, "") ?? 0)
  })
}

function ProductCardRevamped({
  className,
  product: initialProduct,
  siblings,
  trackAsAlgoliaConversion = false,
  hasDrag = true,
  ...rest
}: ProductCardRevampedProps) {
  const locale = useLocale()
  const market = useMarket()

  const { currentProductId } = useProductCardSiblings()

  const queryClient = useQueryClient()

  const currentSibling = queryClient.getQueryState<InferReturn<typeof getSfProduct>>([
    PRODUCT_SIBLING_QUERY_KEY,
    currentProductId,
  ])?.data?.product

  const { ref: inViewRef } = useInView({
    triggerOnce: true,
    onChange(inView) {
      if (inView && siblings?.length > 0) {
        siblings?.forEach((sibling) => {
          queryClient.prefetchQuery({
            queryKey: [PRODUCT_SIBLING_QUERY_KEY, sibling?.id],
          })
        })
      }
    },
  })

  const product = currentSibling || initialProduct

  if (!product) {
    return null
  }

  const variants = serializeVariants(product?.variants, locale)
  const defaultVariant = getDefaultVariant(variants, product)

  const refetchOnClientSide = market === "France"

  return (
    <ErrorBoundary>
      <div key={initialProduct?.id} ref={inViewRef} className={className}>
        <UnlikelyProductCard
          key={product?.id}
          product={product}
          {...(defaultVariant?.id && { defaultVariantId: defaultVariant?.id })}
          queryVariables={{
            id: product?.id,
            metafieldKeys: objectValues(getLocalizedMetafields(productMetafields, locale)),
            metafieldVariantsKeys: objectValues(getLocalizedMetafields(productVariantMetafields, locale)),
            ...PRODUCT_ROOT_FIELDS,
          }}
          defaultQueryOptions={{
            initialData: { product, errors: [] },
            keepPreviousData: true,
            ...(refetchOnClientSide && {
              initialDataUpdatedAt: getTimestampOfNowMinusInterval(),
            }),
            select: (data) => {
              return {
                ...data?.product,
                variants: sortVariants(data?.product?.variants ?? []) ?? [],
              } as UnlikelyProduct
            },
          }}
        >
          {({ ref: abstractRef, onClick }) => {
            return (
              <CardContent
                ref={(node) => {
                  if (!node) {
                    return
                  }

                  abstractRef?.(node)
                }}
                onClick={onClick}
                initialProduct={initialProduct}
                siblings={[
                  { id: initialProduct?.id, handle: initialProduct?.handle, metafields: initialProduct?.metafields },
                  ...siblings,
                ]}
                trackAsAlgoliaConversion={trackAsAlgoliaConversion}
                hasDrag={hasDrag}
                refetchOnClientSide={refetchOnClientSide}
                {...rest}
              />
            )
          }}
        </UnlikelyProductCard>
      </div>
    </ErrorBoundary>
  )
}

function ProductCardRevampedDispatcher({
  siblings: unprocessedSiblings,
  withSiblings,
  ...props
}: ComponentProps<typeof ProductCardRevamped> & { withSiblings?: boolean }) {
  const siblings = unprocessedSiblings?.filter(Boolean) ?? []

  if (withSiblings && siblings?.length > 0) {
    return (
      <ProductCardSiblingsProvider product={props?.product} siblings={siblings}>
        <ProductCardRevamped {...props} siblings={siblings} />
      </ProductCardSiblingsProvider>
    )
  }

  return <ProductCardRevamped {...props} siblings={[]} />
}

export { ProductCardRevampedDispatcher as ProductCardRevamped }
