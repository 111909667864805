"use client"

import { useEffect, useState } from "react"
import { usePathname, useSearchParams } from "next/navigation"

import useLocale from "~/hooks/useLocale"
import MarketPopin from "~/components/ui/MarketPopin"
import { getMarketRedirect, marketCookie } from "~/components/ui/MarketPopin/utils"
import { usePanel } from "~/managers/PanelManager"
import { usePopin } from "~/managers/PopinManager"

export function useMarketRedirect({ country, lang }: { country: string; lang: string }) {
  const locale = useLocale()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  return getMarketRedirect({ lang, country, locale, pathname, searchParams })
}

export function useMarketPopinInit() {
  const { add } = usePopin()
  const { current } = usePanel()

  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    // add a delay to wait for other actions to happen
    setTimeout(() => setIsReady(true), 1000)
  }, [])

  useEffect(() => {
    // make sure there are no panel open
    // otherwise, it will interfere with the clickoutside event + body scroll lock
    if (isReady && !marketCookie.hasInteracted.get() && !current) {
      add(<MarketPopin type="confirm" />)
    }
  }, [isReady, current])
}
